import { Container, Col, Row, NavDropdown, Nav, Navbar } from "react-bootstrap";
import { useLocation, NavLink, Link } from "react-router-dom";
import './Header.scss';
import { FaAngleDown } from 'react-icons/fa';
import { IoCallSharp } from 'react-icons/io5';
import { slide as Menu } from "react-burger-menu";
import { useState } from "react";
import ProgressBar from "react-scroll-progress-bar";
import configUrl from "../config";


function MainNavBar() {

    const [menuOpen, setmenuOpen] = useState(false);
    const openMenu = () => {
        setmenuOpen(true)
    }
    return (
        <>
            <Container fluid className="header">
                <ProgressBar bgcolor="#005de0" />
                <Container>
                    <Row>
                        <Col md={2} lg={2} xl={2} sm={6} xm={6}>
                        <Link className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/`}><img src={`${configUrl.ImgBaseUrl}/images/logo.png`} className="logo" onClick={openMenu} /></Link>
                            
                        </Col>
                        <Col md={10} lg={10} xl={10} sm={6} xm={6} className="d-flex align-items-center header_menu_7sft">

                            <div className="desktop_menu">
                                <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/`}>Home</NavLink>
                                <NavLink className="nav-link main_menu main_services" to={`${process.env.PUBLIC_URL}/services`}>Services
                                    <FaAngleDown className="chevron_icon" />
                                    <ul className="sub_menu_7sft">
                                        <NavLink className="nav-link main_menu mb-2" to={`${process.env.PUBLIC_URL}/services/consulting-services`}>Consulting Services</NavLink>
                                        <NavLink className="nav-link main_menu mb-2" to={`${process.env.PUBLIC_URL}/services/managed-services`}>Managed IT Services</NavLink>
                                        <NavLink className="nav-link main_menu mb-2" to={`${process.env.PUBLIC_URL}/services/software-development`}>Software Development</NavLink>
                                        <NavLink className="nav-link main_menu mb-2" to={`${process.env.PUBLIC_URL}/services/cloud-migration`}>Cloud Migration</NavLink>
                                    </ul>
                                </NavLink>
                                <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/about-us`}>About Us</NavLink>
                                <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/ourteam`}>Our Team</NavLink>
                                <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/careers`}>Careers</NavLink>
                                <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/contact-us`}>Contact Us</NavLink>
                                <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/events`}>Events</NavLink>
                                <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/blogs`}>Blogs</NavLink>
                            </div>




                            {/* mobile menu */}
                            <div className="mobile_menu">
                                <Menu isOpen={menuOpen}>
                                    <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/`}>Home</NavLink>
                                    <NavLink className="nav-link main_menu main_services" to={`${process.env.PUBLIC_URL}/services`}>Services</NavLink>
                                    <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/about-us`}>About Us</NavLink>
                                    <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/ourteam`}>Our Team</NavLink>
                                    <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/careers`}>Careers</NavLink>
                                    <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/contact-us`}>Contact Us</NavLink>
                                    <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/events`}>Events</NavLink>
                                    <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/blogs`}>Blogs</NavLink>
                                </Menu>
                            </div>

                        </Col>
                    </Row>


                </Container>
            </Container>
        </>
    )
}

export default MainNavBar