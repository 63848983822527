import React from 'react';
import { Col, Container, Form, Row, Modal, Button } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { MDBDataTable } from "mdbreact";
import './panel.scss'
import web_services from '../../web_services/web_services';
import { FaEdit } from "react-icons/fa";
import { FaTrashRestoreAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosConfig from "../../axiosConfig";
import configUrl from '../../config';
import { Rings } from 'react-loader-spinner'



function AddBlog() {
    const [loaderTime, setloaderTime] = useState(false);
    const [Title, setTitle] = useState();
    const [BlogDate, setBlogDate] = useState();
    const [BlogImage, setBlogImage] = useState();
    const [BlogUrl, setBlogUrl] = useState();
    const [BlogDescription, setBlogDescription] = useState();
    const [Blogs, setBlogs] = useState([]);
    const [BlogId, setBlogId] = useState();
    const [ApiReload, setApiReload] = useState(false)

    const [titleErr, settitleErr] = useState("");
    const [blogDateErr, setblogDateErr] = useState("");
    const [blogUrlErr, setblogUrlErr] = useState("");
    const [blogImageErr, setblogImageErr] = useState("");
    const [blogDescriptionErr, setblogDescriptionErr] = useState("");

    const [show, setShow] = useState(false);
    var [delItem, setdelItem] = useState(false);
    var [deleteId, setdeleteId] = useState();

    useEffect(() => {
        web_services.getBlogs().then((resBlog) => {
            setBlogs(resBlog.data.blogs ? resBlog.data.blogs : []);
            setApiReload(false)
        })
    }, [ApiReload]);



    const Validater = () => {
        let titleisValid = false;
        let blogDateisValid = false;
        let blogUrlisValid = false;
        let blogImageisValid = false;
        let blogdesValid = false;

        if (Title === "" || Title === undefined || Title.trim() === "") {
            settitleErr("Please Enter a Title");
            titleisValid = false;
        }
        else {
            settitleErr("");
            titleisValid = true;
        }

        if (BlogDate === "" || BlogDate === undefined || BlogDate.trim() === "") {
            setblogDateErr("Please Enter a Date");
            blogDateisValid = false;
        }
        else {
            setblogDateErr("");
            blogDateisValid = true;
        }

        if (BlogUrl == "" || BlogUrl == undefined || BlogUrl == null) {
            setblogUrlErr("Please Enter a URL");
            blogUrlisValid = false;
        }
        else {
            setblogUrlErr("");
            blogUrlisValid = true;
        }

        if (BlogImage == "" || BlogImage == undefined || BlogImage == null) {
            setblogImageErr("Please Enter a Image");
            blogImageisValid = false;
        }
        else {
            setblogImageErr("");
            blogImageisValid = true;
        }

        if (BlogDescription == "" || BlogDescription === undefined || BlogDescription.trim() === "") {
            setblogDescriptionErr("Please Enter a Description");
            blogdesValid = false;
        }
        else {
            setblogDescriptionErr("");
            blogdesValid = true;
        }

        if (blogImageisValid && blogUrlisValid && blogDateisValid && titleisValid && blogdesValid) {
            return true;
        } else {
            return false
        }
    }

    const submitBlog = () => {
        let isvalid = Validater();
        setloaderTime(true)
        if (isvalid) {
            let formData = new FormData();
            formData.append('Title', Title);
            formData.append('Date', BlogDate);
            formData.append('url', BlogUrl);
            formData.append('Description', BlogDescription);
            formData.append('image', BlogImage);

            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("storage")}`,
                    'content-type': 'multipart/form-data'
                },
            }
            console.log(formData)

            if (!BlogId) {
                axiosConfig.post("/addBlog", formData, config)
                    .then(response => {
                        setApiReload(true)
                        setloaderTime(false)
                        setTitle("");
                        setBlogDate("");
                        setBlogUrl("")
                        setBlogDescription("")
                        document.getElementById("exampleFormControlFile1").value = "";
                        toast.success("Blog Successfully Added!");
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                axiosConfig.post("/updateBlog" + "/" + BlogId, formData, config)
                    .then(response => {
                        setApiReload(true)
                        setloaderTime(false)
                        setTitle("");
                        setBlogDate("");
                        setBlogUrl("")
                        setBlogDescription("")
                        document.getElementById("exampleFormControlFile1").value = "";
                        toast.success("Blog Successfully Updated!");
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }

    const updateBlog = (id) => {
        setBlogId(id)
        document.getElementById("exampleFormControlFile1").value = "";
        web_services.getBlogById(id).then((res) => {
            let upData = res.data.blogs[0];
            console.log(upData)
            setTitle(upData.Title);
            setBlogDate(upData.Date);
            setBlogUrl(upData.url)
            setBlogDescription(upData.Description)
        })
    }

    const deleteBlog = (id) => {
        setdeleteId(id)
        setShow(true)

    }

    const handleClose = () => setShow(false);
    const ConfirmDelete = () => {
        delItem = true
        if (delItem === true) {

            web_services.DeleteBlog(deleteId).then((res) => {
                setBlogs(Blogs.filter(com => com.id !== deleteId))
                setApiReload(true)
                toast.error("Blog Deleted!");
                setShow(false);
            })
        }

    }

    const fileUploader = (e) => {
        console.log(e.target.files[0].type)
        if (e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/jpg") {
            setBlogImage(e.target.files[0]);
            setblogImageErr("")
        } else {
            setblogImageErr("File Type Not Supported");
            document.getElementById("exampleFormControlFile1").value = "";
        }
    
      }

    return (
        <>
        {loaderTime ? <div className="loader_submit">
                <Rings color="#005de0" />
            </div> : null}
        <Container className='log_width_container'>
            <Form >
                <Row className='d-flex justify-content-center'>
                    <Col md={8} sm={12} lg={8} xl={8}>
                        <Row>
                            <Col md={6}>
                                <div className="single_input_7sft">
                                    <Form.Control type="text" placeholder="Title" value={Title} onChange={(e) => { setTitle(e.target.value) }} />
                                    <div style={{ color: 'red' }}>{titleErr}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="single_input_7sft">
                                    <input type="file" title="Upload Image" accept=".jpeg,.jpg,.png" class="form-control" id="exampleFormControlFile1" onChange={(e) => {fileUploader(e)}} />
                                    <div style={{ color: 'red' }}>{blogImageErr}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="single_input_7sft">
                                    <input type="date" title="Date" placeholder="Date" class="form-control" id="eventDates" value={BlogDate} onChange={(e) => { setBlogDate(e.target.value) }} />
                                    <div style={{ color: 'red' }}>{blogDateErr}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="single_input_7sft">
                                    <input type="text" title="URL" placeholder="URL" class="form-control" id="eventDates" value={BlogUrl} onChange={(e) => { setBlogUrl(e.target.value) }} />
                                    <div style={{ color: 'red' }}>{blogUrlErr}</div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="single_input_7sft">
                                    <Form.Control as="textarea" rows={5} placeholder="Write A Description" value={BlogDescription} onChange={(e) => { setBlogDescription(e.target.value) }} />
                                    <div style={{ color: 'red' }}>{blogDescriptionErr}</div>
                                </div>
                            </Col>
                            <Col md={12} className='mt-3 d-flex justify-content-end'>
                                <button className="btn_log_7sft mb-3" type='button' onClick={submitBlog}>
                                    Submit
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <MDBDataTable
                striped
                bordered
                small
                className="text-center"
                noBottomColumns
                data={{
                    columns: [
                        {
                            label: "ID",
                            field: "id",
                        },
                        {
                            label: "Blog Title",
                            field: "title",
                        },
                        {
                            label: "Description",
                            field: "desc",
                        },
                        {
                            label: "Date",
                            field: "Date",
                        },
                        {
                            label: "URL",
                            field: "url",
                        },
                        {
                            label: "Image",
                            field: "image",
                        },
                        {
                            label: "Action",
                            field: "action",
                        },
                    ],
                    rows: Blogs.map((resE) => ({
                        id: resE.id,
                        title: resE.Title,
                        desc: resE.Description,
                        Date: resE.Date,
                        url: resE.url,
                        image: (
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={configUrl.APIImagesdURL + resE.image} style={{ width: "100px", height: "120px" }} />
                            </div>

                        ),
                        action: (
                            <div className="d-flex justify-content-center align-items-center">
                                <FaEdit
                                    title="Edit"
                                    className="action_button"
                                    style={{ marginRight: '7px', cursor: 'pointer' }}
                                    onClick={() => updateBlog(resE.id)}
                                />
                                <FaTrashRestoreAlt
                                    title="Delete"
                                    className="action_button icons_Color"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => deleteBlog(resE.id)}
                                />
                            </div>
                        ),
                    })),

                }}
            />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you Sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you really want to delete this record?</Modal.Body>
                <Modal.Footer>
                    <Button  variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={ConfirmDelete}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>

        </>
    )
}
export default AddBlog;