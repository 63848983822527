import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../pages_scss/Home.scss';
import { Container, Row, Col } from "react-bootstrap";
import { FaCalendar, FaLongArrowAltRight } from "react-icons/fa";

import web_services from "../web_services/web_services";
import { useEffect, useState } from "react";
import configUrl from "../config";
import TypeWriterEffect from 'react-typewriter-effect';
import ScrollAnimation from "react-animate-on-scroll";
import { ThreeCircles } from 'react-loader-spinner';


function BLog() {
    const [blogData, setblogData] = useState([]);
    const [blogState, setBlogState] = useState(false)

    useEffect(() => {
            web_services.getBlogs().then((res) => {
                setblogData(res.data.blogs ? res.data.blogs : []);
            })
    }, []);

    return (
        <>
            <div className="page-banner-section event_backImage" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + '/images/blog.png'})` }}>
                <div className="shape-banner2">

                </div>
                {/* <div className="shape-1"></div> */}
                <Container>
                    <Container className="page-banner-wrap">
                        <Row>
                            <Col>
                                <div className="page-banner text-center">
                                    <h2 className="title">Blogs</h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </Container>
            </div>

            <Container className="mt-5 ">
                <div className="blog_wrap_7sft section_margin">
                    <div class="section_title_7sft">
                        <>
                            <ScrollAnimation animateIn='flipInX'>
                                <h3 class="sub_title_7sft">News &amp; Blog</h3>
                            </ScrollAnimation>

                        </>

                        < >
                            <ScrollAnimation animateIn='fadeIn'>
                                <h2 class="title_7sft">Trending article &amp; stories</h2>
                            </ScrollAnimation>

                        </>
                    </div>

                    {/* blog_content_section */}

                    <div className="blog_content_wrap_7sft ">
                            <>
                                {blogData.length >= 1 ? <Row>
                                    {blogData.map((res) => {
                                        return (
                                            <Col lg={4} md={6} xs={12} sm={12}>
                                                <div className="single_blog_7sft">
                                                    <div className="blog_img_7sft">
                                                        <img src={configUrl.APIImagesdURL + res.image} alt="" />
                                                        {/* <div className="top_meta_7sft">
                                        <span className="date_7sft">
                                            <span>08</span>
                                            Aug
                                        </span>
                                    </div> */}
                                                    </div>

                                                    <div className="blog_content_7sft">
                                                        <div className="blog_meta_7sft">
                                                            <span><i><FaCalendar /></i> {res.Date} </span>
                                                            {/* <span><i><FaRegComments /></i> 0 Comments </span> */}
                                                        </div>
                                                        <h5 className="title_7sft">{res.Title}</h5>
                                                        <p>{res.Description}</p>
                                                    </div>
                                                    <div className="blog_btn_7sft">
                                                        <a href={res.url} target="_blank">
                                                            <div className="blog_btn_link_7sft">
                                                                <a >Read Full Article</a>
                                                                <i><FaLongArrowAltRight /></i>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })}

                                </Row> : <div className="data_not_found">
                                    <ThreeCircles
                                        height="80"
                                        width="100%"
                                        color='rgb(0, 93, 224)'
                                    />
                                </div>}
                            </>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default BLog