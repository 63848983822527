import React from 'react';
import { Col, Container, Form, Row, Modal, Button } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { MDBDataTable } from "mdbreact";
import './panel.scss'
import web_services from '../../web_services/web_services';
import { FaEdit, FaTrashRestoreAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { Rings } from 'react-loader-spinner'

function Jobs() {
    const [loaderTime, setloaderTime] = useState(false);
    const [jobTitle, setjobTitle] = useState("");
    const [jobType, setjobType] = useState("");
    const [jobLocation, setjobLocation] = useState("");
    const [jobDescription, setjobDescription] = useState("");
    const [jobReq, setjobReq] = useState("");
    const [jobs, setJob] = useState([]);
    const [jobsTF, setjobsTF] = useState(false);
    const [active, setActivce] = useState(1);
    const [activated, setActivated] = useState(true)
    const [UpdateId, setUpdateId] = useState();

    const [TitleError, setTitleErr] = useState("");
    const [TypeError, setTypeErr] = useState("");
    const [LocationError, setLocationErr] = useState("");
    const [RequirmentsError, setReqErr] = useState("");
    const [DescError, setDescErr] = useState("");

    const [show, setShow] = useState(false);
    var [delItem, setdelItem] = useState(false);
    var [deleteId, setdeleteId] = useState();


    useEffect(() => {
        web_services.getJobs().then((res) => {
            setJob(res.data.jobs ? res.data.jobs : []);
            setjobsTF(false)
        })
    }, [jobsTF])

    const validate = () => {

        let TitleValid = false;
        let TypeValid = false;
        let LocationValid = false;
        let RequirementValid = false;
        let DescValid = false;

        if (jobTitle == "" || jobTitle == undefined || jobTitle.trim() == "") {
            setTitleErr("Please Enter a Title");
            TitleValid = false;
        }
        else {
            setTitleErr("");
            TitleValid = true;
        }

        if (jobType == "" || jobType == undefined || jobType.trim() == "") {
            setTypeErr("Please Enter Job Type");
            TypeValid = false;
        }
        else {
            setTypeErr("");
            TypeValid = true;
        }

        if (jobReq == "" || jobReq == undefined || jobReq.trim() == "") {
            setReqErr("Please Enter Job Requirements");
            RequirementValid = false;
        }
        else {
            setReqErr("");
            RequirementValid = true;
        }

        if (jobDescription == "" || jobDescription == undefined || jobDescription.trim() == "") {
            setDescErr("Please Enter Job Description");
            DescValid = false;
        }
        else {
            setDescErr("");
            DescValid = true;
        }

        if (jobLocation == "" || jobLocation == undefined || jobLocation == "0") {
            setLocationErr("Please Select a Location");
            LocationValid = false;
        }
        else {
            setLocationErr("");
            LocationValid = true;
        }


        if (TitleValid && TypeValid && LocationValid && DescValid && RequirementValid) {
            return true;
        }
        else {
            return false;
        }
    }


    const onSubmitJod = (e) => {
        e.preventDefault();
        let isvalidate = validate();
        setloaderTime(true)
        if (isvalidate) {


            if (!UpdateId) {

                let DataJobs = {
                    job_title: jobTitle,
                    job_requirements: jobReq,
                    job_description: jobDescription,
                    Job_Type: jobType,
                    Job_Location: jobLocation,
                    Active: active
                }
                web_services.AddJobs(DataJobs).then((resJobsadd) => {
                    if (resJobsadd.status == 201) {
                        setloaderTime(false)
                        setjobsTF(true)
                        toast.success("Job Has Been Created.")
                        setjobTitle("");
                        setjobType("");
                        setjobLocation("");
                        setjobDescription("");
                        setjobReq("");
                        setActivce(1);
                        setActivated(true)
                    }

                })
            } else {

                let UpdateDataJobs = {
                    id: UpdateId ? UpdateId : '',
                    job_title: jobTitle,
                    job_requirements: jobReq,
                    job_description: jobDescription,
                    Job_Type: jobType,
                    Job_Location: jobLocation,
                    Active: active
                }
                web_services.UpdateJob(UpdateId, UpdateDataJobs).then((resUp) => {
                    toast.success("Job Has Been Updated.")
                    setloaderTime(false)
                    setjobsTF(true)
                    setjobTitle("");
                    setjobType("");
                    setjobLocation("");
                    setjobDescription("");
                    setjobReq("");
                    setActivce(1);
                    setActivated(true)
                })
            }

        }
    }

    const deleteJobs = (Id) => {
        setdeleteId(Id)
        setShow(true)
    }

    const handleClose = () => setShow(false);

    const confirmDeleteJobs = () => {
        delItem = true
        if (delItem === true) {
            web_services.JobsDelete(deleteId).then(() => {
                setJob(jobs.filter(com => com.id !== deleteId));
                setjobsTF(true)
                toast.error("Job Deleted!");
                setShow(false)
            })
        }
    }


    const activeJobs = (e) => {
        if (e.target.checked == true) {
            setActivce(1)
            setActivated(true)
        } else {
            setActivce(0)
            setActivated(false)
        }
    }

    const updateJobs = (link, id) => {
        setUpdateId(id)
        web_services.JobsgetById(link).then((res) => {
            let upData = res.data.jobs ? res.data.jobs[0] : '';
            setjobTitle(upData.job_title);
            setjobType(upData.Job_Type);
            setjobLocation(upData.Job_Location);
            setjobDescription(upData.job_description);
            setjobReq(upData.job_requirements);
            setActivce(upData.Active);

        })
    }

    useEffect(() => {
        console.log(active)
        if (active == 1) {
            setActivated(true)
        } else {
            setActivated(false)
        }
    }, [active])

    return (
        <>
        {loaderTime ? <div className="loader_submit">
                <Rings color="#005de0" />
            </div> : null}
            <Container className='log_width_container'>
                <Form>
                    <Row className='d-flex justify-content-center'>
                        <Col md={8} sm={12} lg={8} xl={8}>
                            <Row>
                                <Col md={12}>
                                    <div className="single_input_7sft">
                                        <Form.Control type="text" placeholder="Job Title" value={jobTitle} onChange={(e) => { setjobTitle(e.target.value) }} />
                                        <div style={{ color: 'red' }}>{TitleError}</div>
                                    </div>
                                </Col>

                                <Col md={6}>
                                    <div className="single_input_7sft">
                                        <Form.Control type="text" placeholder="Job Type" value={jobType} onChange={(e) => { setjobType(e.target.value) }} />
                                        <div style={{ color: 'red' }}>{TypeError}</div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="single_input_7sft">
                                        <Form.Control as="select" placeholder="Job Location" value={jobLocation} onChange={(e) => { setjobLocation(e.target.value) }} >
                                            <option value={0}>Select Location</option>
                                            <option value="Karachi">Karachi</option>
                                            <option value="Lahore">Lahore</option>
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>{LocationError}</div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="single_input_7sft">
                                        <Form.Control as="textarea" rows={5} placeholder="Job Requirements" value={jobReq} onChange={(e) => { setjobReq(e.target.value) }} />
                                        <div style={{ color: 'red' }}>{RequirmentsError}</div>
                                    </div>
                                </Col>

                                <Col md={6}>
                                    <div className="single_input_7sft">
                                        <Form.Control as="textarea" rows={5} placeholder="Job Description" value={jobDescription} onChange={(e) => { setjobDescription(e.target.value) }} />
                                        <div style={{ color: 'red' }}>{DescError}</div>
                                    </div>
                                </Col>

                                <Col md={12} className='mt-3 d-flex justify-content-between'>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="Active Job"
                                        checked={activated}
                                        onChange={(e) => activeJobs(e)}
                                    />
                                    <button className="btn_log_7sft mb-3" type='button' onClick={onSubmitJod}>
                                        Submit
                                    </button>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Form>

                <MDBDataTable
                    striped
                    bordered
                    small
                    className="text-center mb-5"
                    noBottomColumns
                    data={{
                        columns: [
                            {
                                label: "Job Title",
                                field: "title",
                            },
                            {
                                label: "Job Type",
                                field: "type",
                            },
                            {
                                label: "Job Location",
                                field: "location",
                            },
                            {
                                label: "Date",
                                field: "date",
                            },
                            {
                                label: "Jobs Status",
                                field: "status",
                            },

                            {
                                label: "Actions",
                                field: "action",
                            },
                        ],
                        rows: jobs.map((resJ) => ({
                            title: resJ.job_title,
                            type: resJ.Job_Type,
                            location: resJ.Job_Location,
                            date: new Date(resJ.Posted_Date).toDateString(),
                            status: resJ.Active == 0 ? 'Closed' : 'Active',
                            action: (
                                <div className="d-flex justify-content-center align-items-center">
                                    <FaEdit
                                        title="Edit"
                                        className="action_button"
                                        style={{ marginRight: '7px', cursor: 'pointer' }}
                                        onClick={() => updateJobs(resJ.SharedLink, resJ.id)}
                                    />


                                    <FaTrashRestoreAlt
                                        title="Delete"
                                        className="action_button icons_Color"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => deleteJobs(resJ.id)}
                                    />
                                </div>
                            ),
                        })),
                    }}
                />
            </Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you Sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you really want to delete this record?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger"  onClick={confirmDeleteJobs}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Jobs;