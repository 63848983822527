import React from "react";
import { Navigate } from "react-router-dom";





function Resume(){
    return(
        <Navigate to="/careers"/>
    )
}

export default Resume;

