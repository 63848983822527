import React from 'react';
import { Container, Form, Row, Col } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { MDBDataTable } from "mdbreact";
import './panel.scss'
import web_services from '../../web_services/web_services';
import { Rings } from 'react-loader-spinner'

function ApplyedJobs() {
    const [appliedJob, setappliedJob] = useState([]);
    const [jobs, setJob] = useState([]);
    const [resTitle, setresTitle] = useState('all');

    useEffect(() => {

        web_services.getJobs().then((res) => {
            setJob(res.data.jobs ? res.data.jobs : []);
        });


        if (resTitle === 'all') {
            web_services.getApplyedJobs().then((resApp) => {
                setappliedJob(resApp.data.Appliedjobs ? resApp.data.Appliedjobs : []);
            });
        } else {
            web_services.getAppliedJobById(resTitle).then((resApp) => {
                setappliedJob(resApp.data.Appliedjobs ? resApp.data.Appliedjobs : []);
            });
        }


    }, [resTitle])

    return (
        <>
            <Container className='log_width_container'>
                <Row>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>Search Resume</Form.Label>
                            <Form.Control as="select" defaultValue='all' onChange={(e) => { setresTitle(e.target.value) }}>
                                <option value="all">All</option>
                                {
                                    jobs.map((res) => {
                                        return (
                                            <option value={res.id}>{res.job_title} {res.Active == 0 ? ' (Inactive)' : ''}</option>
                                        )
                                    })
                                }

                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <MDBDataTable
                    striped
                    bordered
                    small
                    className="text-center"
                    noBottomColumns
                    data={{
                        columns: [
                            {
                                label: "Job Title",
                                field: "title",
                            },
                            {
                                label: "Name",
                                field: "name",
                            },
                            {
                                label: "Email",
                                field: "email",
                            },
                            {
                                label: "Phone",
                                field: "phone",
                            },
                            {
                                label: "Applied Date",
                                field: "date",
                            },
                            {
                                label: "Resumes",
                                field: "action",
                            },
                        ],
                        rows: appliedJob.map((resE) => ({
                            title: resE.job_title,
                            name: resE.Name,
                            email: resE.Email,
                            phone: resE.Phone,
                            date: resE.Date.split('T')[0],
                            action: (
                                <div className="d-flex justify-content-center align-items-center">
                                    <a className='btn btn-sm btn-primary btn_download' href={resE.cv} target="_blank" download rel="noreferrer">View  / Download</a>
                                </div>
                            ),
                        })),
                    }}
                />

            </Container>
        </>
    )
}

export default ApplyedJobs;