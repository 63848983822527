import React from "react";
import Footer from "../../components/Footer";
import MainNavBar from "../../components/main_menu";
import { useState, useEffect } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BiSupport } from 'react-icons/bi';
import { MdManageAccounts } from 'react-icons/md';
import { MdMiscellaneousServices } from 'react-icons/md';
import '../../pages_scss/Home.scss';
import { FaCloud } from 'react-icons/fa'
import configUrl from "../../config";


function Services() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <MainNavBar />
      <div className="page-banner-section services_backImage" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + '/images/services.jpg'})` }}>
        <div className="shape-banner2">

        </div>
        <Container>
          <Container className="page-banner-wrap">
            <Row>
              <Col>
                <div className="page-banner text-center">
                  <h2 className="title">Services</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div className="service_section service_margin">
        <Container>
          <div className="service_wraper">
            <Row className="d-flex justify-content-center">
              <Col md={6} lg={3} xl={3} sm={12} xs={12}>
                <NavLink className="navlnk" to="/services/consulting-services">
                  <div className="service-item text-center">
                    <div className="shape-1"></div>
                    <div className="shape-2">
                      <img src={configUrl.ImgBaseUrl + "/images/s-shape-2.png"} alt="shape2" />
                    </div>
                    <div class="service_icon">
                      <BiSupport />
                    </div>
                    <div class="service-content">
                      <h3 class="title">Consulting <br /> Services</h3>
                      <p>Managing IT workloads demands in-depth technology acumen. Our consulting services aim to improve your IT process through proven methodologies and strategies. We provide bespoke IT solutions that fit your operating model and make it more efficient. The outcomes are accelerated business process, faster service delivery, and subtracted costs in the bottom line.</p>
                    </div>
                  </div>
                </NavLink>
              </Col>

              <Col md={6} lg={3} xl={3} sm={12} xs={12}>
                <NavLink className="navlnk" to="/services/managed-services">
                  <div className="service-item text-center">
                    <div className="shape-1"></div>
                    <div className="shape-2">
                      <img src={configUrl.ImgBaseUrl + "/images/s-shape-2.png"} alt="shape2" />
                    </div>
                    <div class="service_icon">
                      <MdManageAccounts />
                    </div>
                    <div class="service-content">
                      <h3 class="title">Managed IT<br /> Services</h3>
                      <p>IT management is an intensive task. We keep all your IT workloads secure within the safe hands of our highly competent and experienced IT consultants, administrators, DBAs, enterprise architects, cloud engineers, and data security experts who deploy top-notch solutions to strengthen your organization’s IT functions and improve operational efficiency to achieve desired performance thresholds.</p>
                    </div>
                  </div>
                </NavLink>
              </Col>

              <Col md={6} lg={3} xl={3} sm={12} xs={12}>
                <NavLink className="navlnk" to="/services/software-development">
                  <div className="service-item text-center">
                    <div className="shape-1"></div>
                    <div className="shape-2">
                      <img src={configUrl.ImgBaseUrl + "/images/s-shape-2.png"} alt="shape2" />
                    </div>
                    <div class="service_icon">
                      <MdMiscellaneousServices />
                    </div>
                    <div class="service-content">
                      <h3 class="title">Software <br /> Development</h3>
                      <p>Software Development requires a blend of technical and functional knowledge and skills. Our veteran application developers have hands-on skills in modern programming languages including C++, Java, and Python. They bring together a diverse skillset in mobile and web application development to help you fill gaps in your application portfolio.</p>
                    </div>
                  </div>
                </NavLink>
              </Col>

              <Col md={6} lg={3} xl={3} sm={12} xs={12}>
                <NavLink className="navlnk" to="/services/cloud-migration">
                  <div className="service-item text-center">
                    <div className="shape-1"></div>
                    <div className="shape-2">
                      <img src={configUrl.ImgBaseUrl + "/images/s-shape-2.png"} alt="shape2" />
                    </div>
                    <div class="service_icon">
                      <FaCloud />
                    </div>
                    <div class="service-content">
                      <h3 class="title">Cloud <br /> Migration</h3>
                      <p>Cloud is the biggest platform to underpin IT workloads. Our cloud solutions are geared towards improving business processes, deescalating costs, and speeding up service delivery. Our cloud consultants and cloud engineers can design end-to-end cloud migration strategy as well as implement application migration with minimum impact on your business applications and databases.</p>
                    </div>
                  </div>
                </NavLink>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Footer />
    </>
  );
}

export default Services;