import React, { Component } from "react";
import './App.scss';
import './pages_scss/mediaQuery.scss'
import { Routes, Route,HashRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import Services from "./pages/services/Services";
import AboutUs from "./pages/AboutUs";
import OurTeam from "./pages/OurTeam";
import ContactUs from "./pages/ContactUs";
import Career from "./pages/Careers";
import Events from "./pages/Events";
import Consulting from "./pages/services/Consulting";
import Managed from "./pages/services/Managed";
import Software from "./pages/services/Software";
import Cloud from "./pages/services/Cloud";
import BlogsCont from "./pages/Blogs";
import SingelEvent from "./pages/single_events";
import Email from "./pages/login/Email";
import Password from "./pages/login/NewPassword";
import Resume from "./pages/resume";
import Sybase from "./pages/sybase";

// sybase-services

import SingleJob from './pages/single_job';
import Login from './pages/login/Login';
import TabForms from './pages/forms/TabForms';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import { useEffect, useState } from "react";
import { Rings } from 'react-loader-spinner'
import 'react-pagination-bar/dist/index.css'
import "animate.css/animate.min.css";



function App() {
  const [loaderTime, setloaderTime] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0)
    
    setTimeout(() => {
      setloaderTime(false);
   }, 2000);


    console.log(process.env.PUBLIC_URL)
  }, [])

  return (

    <>
      {loaderTime ? (<div className="loader">
        <Rings color="#00BFFF" />
      </div>) : (
        <>
          <Routes>
            <Route path={`${process.env.PUBLIC_URL}/`} exact element={<Home />} />
            <Route path={`${process.env.PUBLIC_URL}/services`} element={<Services />} />
            <Route path={`${process.env.PUBLIC_URL}/services/consulting-services`} element={<Consulting />} />
            
            <Route path={`${process.env.PUBLIC_URL}/services/cloud-migration`} element={<Cloud />} />
            <Route path={`${process.env.PUBLIC_URL}/services/sybase-services`} element={<Sybase />} />

            <Route path={`${process.env.PUBLIC_URL}/services/managed-services`} element={<Managed />} />
            <Route path={`${process.env.PUBLIC_URL}/services/software-development`} element={<Software />} />
            <Route path={`${process.env.PUBLIC_URL}/about-us`} element={<AboutUs />} />
            <Route path={`${process.env.PUBLIC_URL}/ourteam`} element={<OurTeam />} />
            <Route path={`${process.env.PUBLIC_URL}/contact-us`} element={<ContactUs />} />
            <Route path={`${process.env.PUBLIC_URL}/careers`} element={<Career />} />
            <Route path={`${process.env.PUBLIC_URL}/resume`} element={<Resume />} />
            <Route path={`${process.env.PUBLIC_URL}/careers/detail/:id`} element={<SingleJob />} />
            <Route path={`${process.env.PUBLIC_URL}/events`}element={<Events />} />
            <Route path={`${process.env.PUBLIC_URL}/events_detail/:id`} element={<SingelEvent />} />
            <Route path={`${process.env.PUBLIC_URL}/hrways`} element={<Login />} />
            <Route path={`${process.env.PUBLIC_URL}/email`} element={<Email />} />
            <Route path={`${process.env.PUBLIC_URL}/reset-password`} element={<Password />} />
            <Route path={`${process.env.PUBLIC_URL}/panel`} element={<TabForms />} athuorized />
            <Route path={`${process.env.PUBLIC_URL}/blogs`} element={<BlogsCont />} />
          </Routes>

          <ToastContainer position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            color=""
            pauseOnHover />

        </>)}

    </>
  );
}

export default App;
