import React from "react";
import Footer from "../../components/Footer";
import MainNavBar from "../../components/main_menu";
import { Container, Col, Row } from "react-bootstrap";
import { useState, useEffect } from 'react';

import ScrollAnimation from "react-animate-on-scroll";
import configUrl from "../../config";

function Software() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MainNavBar />
      <div className="page-banner-section services_backImage" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + '/images/services.jpg'})` }}>
        <div className="shape-banner2">

        </div>
        <Container>
          <Container className="page-banner-wrap ">
            <Row>
              <Col>
                <div className="page-banner text-center">
                  <h2 className="title">Software Development</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <Container>
        <Row className="mt-5">
          <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
            <div className="about_content_7sft">
              <div className="section_title_7sft">
                <div class="section_title_7sft mb-3">

                  <ScrollAnimation animateIn='fadeIn'>
                    <h2 class="title_7sft">Software Development</h2>
                  </ScrollAnimation>



                </div>

                <ScrollAnimation animateIn='flipInX'>
                  <h3 class="sub_title_7sft mb-1">At 7nSoft, We Develop Business Applications and Databases That Fit The Bill</h3>
                </ScrollAnimation>


                <p>
                  Software development is integral for the continuous working of business applications and databases. Today, a software development team supports everything in a digitally-enabled enterprise. A software development process shortens the software development lifecycle and enables lean business processes through faster, error-free, and secure business applications and databases.<br /><br />
                  We have a team of Software Developers and Coders equipped with the right skillset and DevOps toolchain to manage complex business applications and databases or create new software from scratch. Our software solutions have benefitted businesses large and small and allowed them to integrate modern technologies that can de-risk their IT environment from operational and data security threats associated with legacy systems.
                </p>

                <ScrollAnimation animateIn='flipInX'>
                  <h3 class="sub_title_7sft mb-1">An Unparalleled Team of Finest Software Developers and Coders</h3>
                </ScrollAnimation>


                <p>
                  Our team of award-winning Software Developers and Coders possess a mix of skills and knowledge in hardware, operating systems, enterprise applications, and databases and they have deep insights and exposure to working in heterogeneous environments which empower them to provide the right set of solutions for enterprises of any size and complexity.<br /><br />
                  We have delivered ERP systems, CRM applications, e-health suites, and performance management tools to businesses across different industries including manufacturing, healthcare, banking & finance, retail, entertainment, transportation & logistics, and the public sector.<br /><br />
                  Our celebrated developers and coders can help you plan, design, and develop business applications and databases to meet the specific requirements of our clients.<br /><br />
                  Here are key highlights of our software development solutions:
                </p>

                <ul className="list_content">

                  <li><h6 class="sub_title_7sft mb-1">High-Quality Software Solutions</h6></li>

                </ul>
                <p>
                  We are an ISO 9001 certified IT company that provides high-quality software solutions meeting the standards of regulatory bodies. Our team closely complies with the code standards, follows code review best practices, and applies code quality metrics to ensure that codebase is testable, maintainable, editable, and portable on different computing platforms.
                </p>

                <ul className="list_content">

                  <li><h6 class="sub_title_7sft mb-1">Transparent Process</h6></li>

                </ul>
                <p>
                  We believe in building a trustworthy relationship with our clients. This is why we keep everything transparent right from the start of a project. We keep the client in the loop at every stage of the project and provide them with periodic reports including timestamped details of methodologies, milestones, manhours, and budget. Rest assured, our development process is completely auditable from start to finish.
                </p>

                <ul className="list_content">

                  <li><h6 class="sub_title_7sft mb-1">Bespoke Software Solutions</h6></li>

                </ul>
                <p>
                  No two organizations have the same application portfolio. This is why we provide tailored software solutions based on the deep assessment report of our client’s applications. Our software solutions are geared towards improving performance, eliminating technical debts, and ultimately improving operational and cost efficiencies of business applications and databases.
                </p>

                <ul className="list_content">

                  <li><h6 class="sub_title_7sft mb-1">Deadline-Oriented Approach</h6></li>

                </ul>
                <p>
                  We are thoroughly committed to our deadlines. No matter if you need an exclusive ERP system or an enterprise-level CRM for a million customers, we will never miss the deadline. We practice agile software development methodology which allows us to deliver projects with maximum productivity and minimum time and effort. Our 354 successful projects are a proof of our commitment to our valued clients and we will continue to deliver this promise in the future.
                </p>


                <ul className="list_content">

                  <li><h6 class="sub_title_7sft mb-1">24/7 Customer Support Service</h6></li>

                </ul>
                <p>
                  We build open communications with our clients. This is why we have deployed a dedicated team of Customer Support Representatives (CSRs) to allow our clients to connect directly with our team of developers and coders. You can contact our CSRs round the clock and share your feedback with our team at any time of the day.
                </p>

              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default Software;