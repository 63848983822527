import React from "react";
import Footer from "../components/Footer";
import MainNavBar from "../components/main_menu";
import { Container, Col, Row } from "react-bootstrap";
import { useState, useEffect } from 'react';
import "../pages_scss/event.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaCalendar } from 'react-icons/fa'
import web_services from "../web_services/web_services";
import { useNavigate } from "react-router-dom";
import configUrl from "../config";
import ScrollAnimation from "react-animate-on-scroll";
import { ThreeCircles } from 'react-loader-spinner';
import VideoThumbnail from 'react-video-thumbnail';

function Events() {
  const [Events, setEvents] = useState([]);
  const [eventState, seteventState] = useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)

    setTimeout(() => {
      web_services.getEvents().then((resEvent) => {
        setEvents(resEvent.data.events ? resEvent.data.events : []);
        console.log(resEvent.data.events)
      })
      seteventState(true)
    }, 1000)
  }, [])

  const goSingleEvent = (id) => {
    navigate({ pathname: `/events_detail/${id}` })
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
    <>
      <MainNavBar />
      <div className="page-banner-section event_backImage" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + '/images/event.png'})` }}>
        <div className="shape-banner2">

        </div>
        {/* <div className="shape-1"></div> */}
        <Container>
          <Container className="page-banner-wrap">
            <Row>
              <Col>
                <div className="page-banner text-center">
                  <h2 className="title">Events</h2>
                </div>
              </Col>
            </Row>
          </Container>

        </Container>
      </div>

      <Container className="">
        <div className="blog_wrap_7sft section_margin">
          <div class="section_title_7sft">
            <ScrollAnimation animateIn='flipInX'>
              <h3 class="sub_title_7sft">Events</h3>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn'>
              <h2 class="title_7sft">Latest Events</h2>
            </ScrollAnimation>


          </div>
        </div>
        <>
          {Events.length >= 1 ? <Row>
            {Events.map((res, index) => {
              return (
                <Col md={4} lg={4} xl={4} sm={12} xs={12} key={index} onClick={() => { goSingleEvent(res.id) }}>
                  <div className="eventCard_7sft">
                    <Slider {...settings}>
                      {res.images.map((img, i) => {
                        if (img === '') {
                          return null; // Skip rendering if img is empty
                        }
						img=img.toLowerCase();
                        return (
                          <Col md={12} lg={12} xl={12} xs={12} sm={12} className="event_card_height" key={i}>
                            <div className="single-testimonial">
                              <div className="testimonial-content">
                                { img.slice(-3) == 'mp4' ?  
								//<video className='imgUpload mt-4' controls> <source src={configUrl.APIImagesdURL + img} type="video/mp4"/></video>
								<VideoThumbnail videoUrl={configUrl.APIImagesdURL + img}  />
								
								:''}
                                {img.slice(-3) == 'jpg' || img.slice(-3) == 'png' || img.slice(-4) == 'jpeg' || img.slice(-3) == 'bmp' || img.slice(-4) == 'jfif' || img.slice(-4) == 'tfif' || img.slice(-3) == 'gif' ? 
								<img src={configUrl.APIImagesdURL + img} /> 
								: ''}
                              </div>
                            </div>
                          </Col>
                        );
                      })}

                    </Slider>
                    <div className="event_content">
                      <div className="blog_meta_7sft">
                        <span><i><FaCalendar /></i>{res.Date}</span>
                      </div>
                      <h3 className="title_7sft">{res.Title}</h3>
                      <p>{res.Description}</p>
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row> : <div className="data_not_found">
            <ThreeCircles
              height="80"
              width="100%"
              color='rgb(0, 93, 224)'
            />
          </div>}
        </>
      </Container>
      <Footer />
    </>
  );
}

export default Events;