import axiosConfig from "../axiosConfig";



class webServices {
    //contact=========================================================
    AddContact = (contact) => {
        return axiosConfig.post("/contactUs", contact)
    }
    //contact_end=========================================================

    //jobs=============================================================
    getJobs = () => {
        return axiosConfig.get("/getalljobs")
    }

    AddJobs = (jobs) => {
        return axiosConfig.post("/addJob", jobs, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
            },
        })
    }

    JobsgetById = (jobsId) => {
        return axiosConfig.get("/getJobById" + "/" + jobsId, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
            },
        })
    }

    JobsDelete = (jobsId) => {
        return axiosConfig.delete("/delJob" + "/" + jobsId, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin' : "*"
            },
        })
    }

    UpdateJob = (jobsId, jobData) => {
        return axiosConfig.post("/updateJob" + "/" + jobsId, jobData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin' : "*"
            },
        })
    }
    //jobs_end================================================================

    //employees ========================================================
    getEmployees = () => {
        return axiosConfig.get("/getallemployees")
    }

    getEmployeeByID = (Id) => {
        return axiosConfig.get("/getEmployeeById" + "/" + Id, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin' : "*"
            }, 
        })
    }

    EmployeeDelete = (Id) => {
        return axiosConfig.delete("/delEmployeeById" + "/" + Id, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin' : "*"
            },
        })
    }


    //single job =================================
    ApplyJob = (data) => {
        return axiosConfig.post("/applyJob", data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin' : "*"
            },
        })

    }

    getApplyedJobs = () => {
        return axiosConfig.get("/getAppliedJobs", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin' : "*"
            },
        })
    }
    getAppliedJobById =(id) => {
        return axiosConfig.get("/getAppliedJobById/" + id, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin' : "*"
            },
        })
    }
    //Events =================================
    getEvents = () => {
        return axiosConfig.get("/getEvents")
    }

    DeleteEvents = (Id) => {
        return axiosConfig.delete("/delEvent" + "/" + Id, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin' : "*"
            },
        })
    }

    DeleteEventImages = (Id) => {
        return axiosConfig.delete("/delEventImageById" + "/" + Id, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin' : "*"
            },
        })
    }

    getEventById = (id) => {
        return axiosConfig.get("/getEventById" + "/" + id)
    }

    //Blog =================================
    getBlogs = () => {
        return axiosConfig.get("/getBlogs")
    }

    getBlogsForHome = () => {
        return axiosConfig.get("/getBlogsForHome")
    }


    getBlogById = (id) => {
        return axiosConfig.get("/getBlogById" + "/" + id, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin' : "*"
            },
        })
    }


    DeleteBlog = (Id) => {
        return axiosConfig.delete("/delBlogById" + "/" + Id, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin' : "*"
            },
        })
    }


    getPic = () => {
        return axiosConfig.get("/getSneakpeak")
    }

    changePassword = (password) =>{
        return axiosConfig.post("/changePassword",password,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("storage")}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin' : "*"
            },
        })
    }
}

export default new webServices();