import React from "react";
import { Navigate } from "react-router-dom";



function Sybase() {
    return(
        <Navigate to="/services/cloud"/>
    )
}


export default Sybase;