import React from 'react';
import BLog from '../components/Blogs';
import MainNavBar from '../components/main_menu';
import { useEffect } from 'react';
import Footer from '../components/Footer';
 






const BlogsCont = () =>{
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <>
         <MainNavBar />
        <BLog/>
        <Footer/>
        </>
    )
}

export default BlogsCont;