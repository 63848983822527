import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../pages_scss/Home.scss';
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { BiSupport } from 'react-icons/bi';
import { MdManageAccounts } from 'react-icons/md';
import { MdMiscellaneousServices } from 'react-icons/md';
import { FaCloud } from 'react-icons/fa'
import { FaLinkedinIn } from 'react-icons/fa';
import { NavLink } from "react-router-dom";
import { useState, useEffect } from 'react';
import '../pages_scss/Testimonial.scss';

import Counter from "../components/Counter";
import { FaCalendar, FaLongArrowAltRight } from "react-icons/fa";
import web_services from "../web_services/web_services";
import configUrl from "../config";

import ScrollAnimation from 'react-animate-on-scroll';

function Home() {
  const [loaderTime, setloaderTime] = useState(true);
  const [blogData, setblogData] = useState([]);
  const [employeesData, setEmp] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0)
    setTimeout(() => {
      setloaderTime(false);
    }, 4000);

    web_services.getBlogsForHome().then((res) => {
      setblogData(res.data.blogs ? res.data.blogs : []);
    })

    web_services.getEmployees().then((res) => {
      setEmp(res.data.employees ? res.data.employees : []);
    })
  }, [])


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: employeesData.length >= 3 ? 3 : employeesData.length,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: employeesData.length >= 3 ? 3 : employeesData.length,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 3000,
          speed: 500,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          speed: 500,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          speed: 500,
        }
      }
    ]
  };


  return (
    <>

      <>
        <Header />
        <div className="service_section">
          <Container>
            <div className="service_wraper">
              <Row className="d-flex justify-content-center">
                <Col md={6} lg={3} xl={3} sm={12} xs={12}>
                  <NavLink className="navlnk" to="/consulting">
                    <div className="service-item text-center">
                      <div className="shape-1"></div>
                      <div className="shape-2">
                        <img src={`${configUrl.ImgBaseUrl}/images/s-shape-2.png`} alt="shape2" />
                      </div>
                      <div class="service_icon">
                        <BiSupport />
                      </div>
                      <div class="service-content">
                        <h3 class="title">Consulting <br /> Services</h3>
                        <p>Managing IT workloads demands in-depth technology acumen. Our consulting services aim to improve your IT process through proven methodologies and strategies. We provide bespoke IT solutions that fit your operating model and make it more efficient. The outcomes are accelerated business process, faster service delivery, and subtracted costs in the bottom line.</p>
                      </div>
                    </div>
                  </NavLink>
                </Col>

                <Col md={6} lg={3} xl={3} sm={12} xs={12}>
                  <NavLink className="navlnk" to="/managed">
                    <div className="service-item text-center">
                      <div className="shape-1"></div>
                      <div className="shape-2">
                        <img src={`${configUrl.ImgBaseUrl}/images/s-shape-2.png`} alt="shape2" />
                      </div>
                      <div class="service_icon">
                        <MdManageAccounts />
                      </div>
                      <div class="service-content">
                        <h3 class="title">Managed IT<br /> Services</h3>
                        <p>IT management is an intensive task. We keep all your IT workloads secure within the safe hands of our highly competent and experienced IT consultants, administrators, DBAs, enterprise architects, cloud engineers, and data security experts who deploy top-notch solutions to strengthen your organization’s IT functions and improve operational efficiency to achieve desired performance thresholds.</p>
                      </div>
                    </div>
                  </NavLink>
                </Col>

                <Col md={6} lg={3} xl={3} sm={12} xs={12}>
                  <NavLink className="navlnk" to="/softwaredev">
                    <div className="service-item text-center">
                      <div className="shape-1"></div>
                      <div className="shape-2">
                        <img src={`${configUrl.ImgBaseUrl}/images/s-shape-2.png`} alt="shape2" />
                      </div>
                      <div class="service_icon">
                        <MdMiscellaneousServices />
                      </div>
                      <div class="service-content">
                        <h3 class="title">Software <br /> Development</h3>
                        <p>Software Development requires a blend of technical and functional knowledge and skills. Our veteran application developers have hands-on skills in modern programming languages including C++, Java, and Python. They bring together a diverse skillset in mobile and web application development to help you fill gaps in your application portfolio.</p>
                      </div>
                    </div>
                  </NavLink>
                </Col>

                <Col md={6} lg={3} xl={3} sm={12} xs={12}>
                  <NavLink className="navlnk" to="/cloud">
                    <div className="service-item text-center">
                      <div className="shape-1"></div>
                      <div className="shape-2">
                        <img src={`${configUrl.ImgBaseUrl}/images/s-shape-2.png`} alt="shape2" />
                      </div>
                      <div class="service_icon">
                        <FaCloud />
                      </div>
                      <div class="service-content">
                        <h3 class="title">Cloud <br /> Migration</h3>
                        <p>Cloud is the biggest platform to underpin IT workloads. Our cloud solutions are geared towards improving business processes, deescalating costs, and speeding up service delivery. Our cloud consultants and cloud engineers can design end-to-end cloud migration strategy as well as implement application migration with minimum impact on your business applications and databases.</p>
                      </div>
                    </div>
                  </NavLink>
                </Col>
              </Row>
            </div>

            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
                <div className="about_content_7sft">
                  <div className="section_title_7sft">
                    <div class="section_title_7sft">
                      <ScrollAnimation animateIn='flipInX'>
                        <h3 class="sub_title_7sft">Who We Are</h3>
                      </ScrollAnimation>



                      <ScrollAnimation animateIn='flipInX'>
                        <h2 class="title_7sft">The Best IT Solutions Are Executed By the Best People</h2>
                      </ScrollAnimation>



                    </div>
                    <p>
                      IT modernization is a non-linear process that requires insights from industry experts. We are a team of supremely qualified and knowledgeable IT experts who have a deep understanding of business functions. They can chart your organization’s journey towards IT modernization and provide value-added solutions which converge innovation with cost efficiencies.<br /><br />
                      Our team of unrivaled technology consultants, IT specialists, DBAs, cloud professionals, and data security analysts offers business-relevant solutions to align IT frameworks with the functional needs of an enterprise to create value to end users. The result is an efficient, cost-effective, and unbreakable IT process that supports innovation and diminishes costs.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <Counter />
        </div>

        <div className="section features_section_7sft" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + '/images/features-bg.jpg'})` }}>
          <div className="shap1_7sft">
            <img src={`${configUrl.ImgBaseUrl}/images/features-shape.png`} alt="" />
          </div>

          <Container>
            <div className="features_wrap_7sft">
              <Row>
                <Col xl={5} md={5} xs={12} sm={12} >

                </Col>
                <Col xl={7} md={7} xs={12} sm={7} >
                  <div className="features_content_wrap_7sft">
                    <div class="section_title_7sft">

                      <ScrollAnimation animateIn='flipInX'>
                        <h3 class="sub_title_7sft">WHY CHOOSE US</h3>
                      </ScrollAnimation>


                      <ScrollAnimation animateIn='flipInX'>
                        <h2 class="title_7sft">What Makes Us Stand Out?</h2>
                      </ScrollAnimation>



                    </div>
                  </div>
                  <div className="features_content_7sft">
                    <ul className="feture_ul_7sft">
                      <li className="features_item_7sft">
                        <div className="features_icon_7sf">
                          <img src={`${configUrl.ImgBaseUrl}/icons/features-1.png`} alt='' />
                        </div>
                        <div className="features_text_7sft">
                          <h5 class="title_7sft">A Team of Distinguished IT Experts</h5>
                          <p>Our team of IT experts and consultants can offer in-depth analysis of critical issues and assist you in resolving them to keep your IT systems running seamlessly across the organization.</p>
                        </div>
                      </li>

                      <li className="features_item_7sft">
                        <div className="features_icon_7sf">
                          <img src={`${configUrl.ImgBaseUrl}/icons/features-2.png`} alt='' />
                        </div>
                        <div className="features_text_7sft">
                          <h5 class="title_7sft">ISO 9001 Certified and ISO 27001 Certified IT Services</h5>
                          <p>We comply with defined quality assurance and data security standards including ISO 9001 and ISO 27001 standards.</p>
                        </div>
                      </li>

                      <li className="features_item_7sft">
                        <div className="features_icon_7sf">
                          <img src={`${configUrl.ImgBaseUrl}/icons/features-3.png`} alt='' />
                        </div>
                        <div className="features_text_7sft">
                          <h5 class="title_7sft">Technology Integration</h5>
                          <p>Our technology consultants are equipped with the right skillset to help you integrate turnkey technology solutions.</p>
                        </div>
                      </li>

                      <li className="features_item_7sft">
                        <div className="features_icon_7sf">
                          <img src={`${configUrl.ImgBaseUrl}/icons/features-4.png`} alt='' />
                        </div>
                        <div className="features_text_7sft">
                          <h5 class="title_7sft">Round The Clock Support</h5>
                          <p>We are just a call away to rescue your IT process. Our support service is available 24/7.</p>
                        </div>
                      </li>
                    </ul>
                  </div>


                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {employeesData.length == 0 ? null : <div className="section team-section section-padding">
          <Container>
            <div className="team-wrap">
              <div className="section-title text-center">

                <ScrollAnimation animateIn='flipInX'>
                  <h3 className="sub-title">Our Team of Top-Rated Professionals</h3>
                </ScrollAnimation>


                <ScrollAnimation animateIn='flipInX'>
                  <h2 className="title"> Meet Our IT Experts and Technology Consultants </h2>
                </ScrollAnimation>


              </div>

              <div className="team-content-wrap">
                <Container>
                  <Container>
                    <Slider {...settings}>
                      {employeesData.map((resEmp, i) => {
                        return (
                          <Col md={3} lg={3} xs={12} sm={12} key={i}>

                            <div className="single-testimonial">
                              <div className="testimonial-content">
                                <div className="single-team">
                                  <div className="team-img">
                                    <a href="#">
                                      <img src={configUrl.APIImagesdURL + resEmp.Profile_Picture} alt="team1" />
                                    </a>
                                  </div>

                                  <div className="team-content">
                                    <div className="team-social">
                                      <ul className="social">
                                        <li><FaLinkedinIn /></li>
                                      </ul>
                                    </div>
                                    {/* <h3 className="name">{resEmp.Name}</h3> */}
                                    <span className="designation">{resEmp.Designation}</span>
                                  </div>
                                  <div className="emp_des_7sft">
                                    <p>{resEmp.Description}</p>
                                  </div>
                                </div>

                              </div>
                            </div>

                          </Col>
                        )
                      })}

                    </Slider>
                  </Container>
                </Container>
              </div>
            </div>
          </Container>
          <div className="case-study-btn text-center">
            <NavLink className="btn_7sft" to="/ourteam">
              See More
            </NavLink>
          </div>
        </div>}



        {/* blog_section */}

        {blogData.length >= 1 ? <>
          <Container className="mt-5 ">
            <div className="blog_wrap_7sft section_margin">
              <div class="section_title_7sft">

                <ScrollAnimation animateIn='flipInX'>
                  <h3 class="sub_title_7sft">News &amp; Blog</h3>
                </ScrollAnimation>

                <ScrollAnimation animateIn='flipInX'>
                  <h2 class="title_7sft">Trending article &amp; stories</h2>
                </ScrollAnimation>

              </div>

              {/* blog_content_section */}

              <div className="blog_content_wrap_7sft ">
                <Row>
                  {blogData.map((res) => {
                    return (
                      <Col lg={4} md={6} xs={12} sm={12}>
                        <div className="single_blog_7sft">
                          <div className="blog_img_7sft">
                            <img src={configUrl.APIImagesdURL + res.image} alt="" />
                            {/* <div className="top_meta_7sft">
                                        <span className="date_7sft">
                                            <span>08</span>
                                            Aug
                                        </span>
                                    </div> */}
                          </div>

                          <div className="blog_content_7sft">
                            <div className="blog_meta_7sft">
                              <span><i><FaCalendar /></i> {res.Date} </span>
                              {/* <span><i><FaRegComments /></i> 0 Comments </span> */}
                            </div>
                            <h5 className="title_7sft">{res.Title}</h5>
                            <p>{res.Description}</p>
                          </div>
                          <div className="blog_btn_7sft">
                            <a href={res.url} target="_blank">
                              <div className="blog_btn_link_7sft">
                                <a >Read Full Article</a>

                                <i><FaLongArrowAltRight /></i>
                              </div>
                            </a>
                          </div>
                        </div>
                      </Col>
                    )
                  })}

                </Row>
              </div>
            </div>
          </Container>

          <div className="case-study-btn text-center mb-5 mt-3">
            <NavLink className="btn_7sft" to="/blogs">
              See More
            </NavLink>
          </div>
        </> : null}


        {/*blog end*/}
        <Footer />
      </>

    </>
  );
}

export default Home;