import React from 'react';
import { Tab } from 'react-bootstrap';
import { Button, Col, Container, Form, Row, Nav, Modal, Dropdown } from "react-bootstrap";
import { IoIosLogOut } from 'react-icons/io'

import { useState } from 'react';
import './panel.scss'
import web_services from '../../web_services/web_services';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import EmployeeDirectory from './EmployeeDirectory';
import ApplyedJobs from './ApplyedJobs';
import Jobs from './Jobs';
import AddEvent from './AddEvent';
import AddBlog from './AddBlog'
import { Navigate } from "react-router-dom"
import { AiTwotoneSetting } from "react-icons/ai";
import configUrl from '../../config';


function TabForms({ athuorized }) {
    const [keyName, setKeyName] = useState("");
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const [pass, setpass] = useState("");
    const [oldPass, setoldPass] = useState("");
    const [Chpass, setChpass] = useState("");
    const [errPass, seterrPass] = useState('')


    const selectRun = (k) => {
        setKeyName(k);
        localStorage.setItem("activeKey", k);
        //window.location.reload();
    }
    const logout = () => {
        localStorage.removeItem("storage");
        navigate('/hrways')
        toast.info("Logged Out!");
    }


    if (localStorage.getItem("storage")) {
        athuorized = true;
    } else {
        athuorized = false;
        return <Navigate to="/hrways" />
    }


    const handleClose = () => {
        setShow(false);
        seterrPass('');
        setpass("");
        setoldPass("");
        setChpass("");
    }

    const handleOpen = () => setShow(true);

    const passValidator = () => {
        let validatePass = false;



        if (Chpass === "" || Chpass.trim() === "" || pass === "" || pass.trim() === "" || oldPass === "" || oldPass.trim() === "") {

            seterrPass('Confirm Password');
            validatePass = false;
        } else if (Chpass !== pass) {

            seterrPass('Passwords do not match');
            validatePass = false;
        }
        else {
            seterrPass('');
            validatePass = true;
        }



        if (validatePass) {
            return true;
        } else {
            return false;
        }

    }
    const changePass = () => {
        let passwordValidator = passValidator();

        if (passwordValidator) {
            let data = {
                password: pass,
                id: localStorage.getItem('userId'),
                old_password: oldPass
            }

            web_services.changePassword(data).then((res) => {
                toast.info("Password Changed Successfully");
                console.log(res.data)
                setShow(false)
                setpass("");
                setoldPass("");
                setChpass("");
            })
        }


    }

    return (
        <>
            <Container fluid>
                <div className='main_tab'>
                    <Tab.Container id="left-tabs-example" activeKey={localStorage.getItem("activeKey")} onSelect={(k) => selectRun(k)}>
                        <Row>
                            <Col sm={12} lg={2} xl={2} md={12} className='tab_nav_7sft'>
                                <div className='d-flex align-items-center justify-content-center mb-3 p-3'>
                                    <img src={`${configUrl.ImgBaseUrl}/images/7n-logo.svg`} className="logo" />
                                </div>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first"  >EMPLOYEE DIRECTORY</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second" >JOBS</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third" >APPLIED JOBS</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="forth" >EVENT</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="fifth" >BLOGS</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={12} xs={12} lg={10} xl={10} md={10} className='tabs_page_7sft'>
                                <div className='herder_panel_7sft'>
                                    <Row>
                                        <Col md={3} className='panel_heding_7sft'>

                                        </Col>
                                        <Col md={6} className='panel_heding_7sft text-center'>
                                            <h1>Admin Panel</h1>
                                        </Col>
                                        <Col md={3} className='panel_heding_icons' >
                                            <Dropdown>
                                                <Dropdown.Toggle id="dropdown-button-dark-example1" className="btn_log_7sft">
                                                    <AiTwotoneSetting style={{ fontSize: '20px' }} />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu variant="light">
                                                    <ul className='drop'>
                                                        <li onClick={handleOpen}> Change Password</li>
                                                        <li onClick={logout}> Logout <IoIosLogOut /></li>
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>

                                    </Row>
                                </div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <h4 className="title d-flex justify-content-center text-align-center mt-3" >
                                            EMPLOYEE DIRECTORY
                                        </h4>

                                        <EmployeeDirectory />

                                    </Tab.Pane>

                                    <Tab.Pane eventKey="second" className="log_width">
                                        <h4 className="title d-flex justify-content-center text-align-center mt-3" >
                                            JOBS
                                        </h4>
                                        <Jobs />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="third">
                                        <h4 className="title d-flex justify-content-center text-align-center mt-3" >
                                            Applied Jobs
                                        </h4>
                                        <ApplyedJobs />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="forth">
                                        <h4 className="title d-flex justify-content-center text-align-center mt-3" >
                                            Event
                                        </h4>
                                        <AddEvent />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="fifth">
                                        <h4 className="title d-flex justify-content-center text-align-center mt-3" >
                                            BLOG
                                        </h4>
                                        <AddBlog />
                                    </Tab.Pane>

                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} className="mt-3">
                            <div className="single_input_7sft">
                                <Form.Control type="password" placeholder="Old Password" value={oldPass} onChange={(e) => { setoldPass(e.target.value) }} />
                            </div>
                        </Col>
                        <Col md={12} className="mt-3">
                            <div className="single_input_7sft">
                                <Form.Control type="password" placeholder="New Password" value={pass} onChange={(e) => { setpass(e.target.value) }} />
                            </div>
                        </Col>

                        <Col md={12} className="mt-3">
                            <div className="single_input_7sft">
                                <Form.Control type="password" placeholder="Confirm Password" value={Chpass} onChange={(e) => { setChpass(e.target.value) }} />
                                <div style={{ color: 'red' }}>{errPass}</div>
                            </div>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={changePass}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TabForms;