import React from "react";
import MainNavBar from "../components/main_menu";
import Footer from "../components/Footer";
import { Container, Col, Row, Form } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { NavLink } from "react-bootstrap";
import '../pages_scss/single_job.scss';
import '../pages_scss/ContactUs.scss';
import web_services from "../web_services/web_services";
import axiosConfig from "../axiosConfig";
import { toast } from "react-toastify";
import { FaRegMoneyBillAlt, FaHeartbeat, FaCalendarAlt, FaBalanceScaleRight } from "react-icons/fa"
import { MdHolidayVillage } from "react-icons/md";
import { BsGraphUp } from "react-icons/bs";
import { BsSunFill } from "react-icons/bs"
import configUrl from "../config";
import { GrRefresh } from 'react-icons/gr';
import ClientCaptcha from "react-client-captcha"
import ScrollAnimation from "react-animate-on-scroll";


function SingleJob(props) {
  const { id } = useParams();
  const [job, setjob] = useState();
  const [jobdata, setjobdata] = useState({});

  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [cv, setCV] = useState("");
  const [Capt, setCapt] = useState("");
  const [SubCapt, setSubCapt] = useState("");

  const [nameError, setNameErr] = useState("");
  const [emailError, setEmailErr] = useState("");
  const [phoneError, setPhoneErr] = useState("");
  const [cvError, setCVErr] = useState("");
  const [CaptchaError, setCaptchaErr] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0)
    web_services.JobsgetById(id).then((res) => {
      setjob(res.data.jobs ? res.data.jobs[0] : []);
    })
  }, [])

  const captchaHandler = (cap) => {
    setCapt(cap);
  }

  const validate = () => {

    let NameValid = false;
    let EmailValid = false;
    let PhoneValid = false;
    let CvValid = false;
    let CaptchaValid = false;

    if (Name == "" || Name.trim() == "") {
      setNameErr("Please Enter a Name");
      NameValid = false;
    }
    else {
      setNameErr("");
      NameValid = true;
    }

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (Email == "" || Email.trim() == "") {
      setEmailErr("Please Enter an Email");
      EmailValid = false;
    }
    else if (!pattern.test(Email)) {
      setEmailErr("Invalid Email");
      EmailValid = false;
    }
    else {
      setEmailErr("");
      EmailValid = true;
    }

    if (Phone == "" || Phone.trim() == "" || Phone.length != "11") {
      setPhoneErr("Please Enter a Valid Number");
      PhoneValid = false;
    }
    else {
      setPhoneErr("");
      PhoneValid = true;
    }

    if (cv == "" || cv == null || cv == undefined) {
      setCVErr("Attach your Resume");
      CvValid = false;
    }
    else {
      setCVErr("");
      CvValid = true;
    }

    if (Capt === SubCapt) {
      setCaptchaErr("");
      CaptchaValid = true;
    }
    else {
      setCaptchaErr("Invalid Captcha");
      CaptchaValid = false;
    }


    if (NameValid && EmailValid && PhoneValid && CvValid && CaptchaValid) {
      return true;
    }
    else {
      return false;
    }
  }

  const submitApp = (e) => {
    document.querySelector('#retryButton').click()

    let isvalidate = validate();
    if (isvalidate) {
      let formData = new FormData();
      formData.append('Job_Id', job.id);
      formData.append('Job_title', job.job_title);
      formData.append('Name', Name);
      formData.append('Email', Email);
      formData.append('Phone', Phone);
      formData.append('cv', cv);


      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
      }

      axiosConfig.post("/applyJob", formData, config)
        .then(response => {
          console.log(response);
          setName("");
          setEmail("");
          setPhone("");
          setCV("");
          document.getElementById("exampleFormControlFile1").value = "";
          setSubCapt("");
          toast.info(response.data.message);
          document.querySelector('#retryButton').click()
          // setjobsTF(true);
        })
    }
  }

  const fileUploader = (e) => {
    console.log(e.target.files[0].type)
    if (e.target.files[0].type == "application/pdf" || e.target.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      setCV(e.target.files[0]);
      setCVErr("")
    } else {
      setCVErr("File Type Not Supported")
      document.getElementById("exampleFormControlFile1").value = "";
    }

  }

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      submitApp();
    }
  };

  return (
    <>
      <MainNavBar />

      <div className="page-banner-section career_backImage" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + '/images/career.jpg'})` }}>
        <div className="shape-banner2">

        </div>
        {/* <div className="shape-1"></div> */}
        <Container>
          <Container className="page-banner-wrap" >
            <Row>
              <Col>
                <div className="page-banner text-center">
                  <h2 className="title">{job ? job.job_title : null}</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <Container>
        <Row className="mt-5">
          <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
            <div className="about_content_7sft job_con">
              <div className="section_title_7sft">
                <div class="section_title_7sft">
                  <ScrollAnimation animateIn='flipInX'>
                    <h3 class="sub_title_7sft" style={{ marginTop: '0px' }}>Careers</h3>
                  </ScrollAnimation>

                  <ScrollAnimation animateIn='fadeInX'>
                    <h2 class="title_7sft" style={{ marginTop: '0px' }}>{job ? job.job_title : null}</h2>
                  </ScrollAnimation>

                </div>
                <p>
                  We are looking for {job ? job.job_title : null}.
                </p>

                <ScrollAnimation animateIn='flipInX'>
                  <h2 class="sub_title_7sft">Requirements</h2>
                </ScrollAnimation>

                <p className="ul_list">
                  {job ? job.job_requirements : null}
                </p>

                <ScrollAnimation animateIn='flipInX'>
                  <h2 class="sub_title_7sft ">Descriptions</h2>
                </ScrollAnimation>


                <p className="ul_list">
                  {job ? job.job_description : null}
                </p>

                <ScrollAnimation animateIn='flipInX'>
                  <h2 class="sub_title_7sft ">Benefits</h2>
                </ScrollAnimation>

                <ul className="ul_list benif">
                  <li><i><FaRegMoneyBillAlt /></i>  Market competitive salary</li>
                  <li><i><FaHeartbeat /></i> Medical Insurance (Self, Spouse & Children), Life Insurance, OPD allowance</li>
                  <li><i><MdHolidayVillage /></i> Annual Leaves</li>
                  <li><i><BsGraphUp /></i> Handsome annual increments</li>
                  <li><i><FaCalendarAlt /></i> 2 Weekly off</li>
                  <li><i><FaBalanceScaleRight /></i>Work life balance</li>
                  <li><i><BsSunFill /></i> Employee engagement activities</li>

                </ul>

                <ScrollAnimation animateIn='flipInX'>
                  <h2 class="sub_title_7sft ">Type</h2>
                </ScrollAnimation>

                <p className="ul_list" >
                  {job ? job.Job_Type : null}
                </p>

                <ScrollAnimation animateIn='flipInX'>
                  <h2 class="sub_title_7sft ">Location</h2>
                </ScrollAnimation>

                <p className="ul_list">
                  {job ? job.Job_Location : null}
                </p>


              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="contact_form_section section_margin">
        <Container>
          <div className="contact_wrap_7sft">
            <Row className="justify-content-center">
              <Col md={8} lg={8} sm={12} xs={12} xl={8}>
                <div className="form_title_7sft">
                  <ScrollAnimation animateIn='fadeIn'>
                    <h3 className="title">
                      Apply Now
                    </h3>
                  </ScrollAnimation>

                </div>

                <div className="contact_form_wrap_7sft">
                  <Row>
                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Label>Your Name <i style={{ color: 'red' }}>*</i></Form.Label>
                        <Form.Control type="text" value={Name} onChange={(e) => { setName(e.target.value) }} />
                        <div style={{ color: 'red' }}>{nameError}</div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Label>E-mail <i style={{ color: 'red' }}>*</i></Form.Label>
                        <Form.Control type="text" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
                        <div style={{ color: 'red' }}>{emailError}</div>

                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Label>Phone number<i style={{ color: 'red' }}>*</i></Form.Label>
                        <Form.Control type="number" value={Phone} onChange={(e) => { setPhone(e.target.value.slice(0, 11)) }} />
                        <div style={{ color: 'red' }}>{phoneError}</div>


                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Label>Upload CV<i style={{ color: 'red' }}>*</i></Form.Label>
                        <input type="file" title="Upload CV" accept=".pdf,.doc,.docs,.docx" class="form-control" id="exampleFormControlFile1" onChange={(e) => { fileUploader(e) }} />
                        <div style={{ color: 'red' }}>{cvError}</div>
                        <div style={{ color: 'gray', fontSize: '13px' }} className="mt-2">Supported Formats: pdf/docx </div>

                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="d-flex align-items-center">
                        <div className="single_input_7sft">
                          <ClientCaptcha
                            captchaCode={captchaHandler}
                            fontColor="#ffffff"
                            backgroundColor="#005de0"
                          />

                        </div>

                        <div className="single_input_7sft" style={{ marginLeft: '15px' }}>
                          <Form.Control type="text" placeholder="Verify Captcha" value={SubCapt} onKeyPress={(e) => handleKeypress(e)} onChange={(e) => setSubCapt(e.target.value)} />
                        </div>
                      </div>
                      <div style={{ color: 'red' }}>{CaptchaError}</div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <div className="case-study-btn text-center mt-3">
              <NavLink className="btn_7sft" to="/ourteam" onClick={submitApp}>
                Submit
              </NavLink>
            </div>
          </div>
        </Container>
      </div>

      <Footer />
    </>
  )
}

export default SingleJob;