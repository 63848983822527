import React from "react";
import MainNavBar from "../components/main_menu";
import Footer from "../components/Footer";
import { Container, Col, Row } from "react-bootstrap";
import { FaLinkedinIn } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import '../pages_scss/Home.scss';
import web_services from "../web_services/web_services";

import configUrl from "../config";
import ScrollAnimation from "react-animate-on-scroll";

function OurTeam() {
  const [employeesData, setEmp] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0)
    web_services.getEmployees().then((res) => {
      setEmp(res.data.employees ? res.data.employees : []);
    })
  }, [])

  return (
    <>
      <MainNavBar />

      <div className="page-banner-section our_team_backImage" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + 'images/our_team.jpg'})` }}>
        <div className="shape-banner2">

        </div>
        <Container>
          <Container className="page-banner-wrap">
            <Row>
              <Col>
                <div className="page-banner text-center">
                  <h2 className="title">Our Team</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      {/* CONTENT */}

      <Container>
        <Row className="mt-5">
          <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
            <div className="about_content_7sft">
              <div className="section_title_7sft">
                <div class="section_title_7sft mb-3">
                  <ScrollAnimation animateIn='fadeIn'>
                    <h2 class="title_7sft">Our Team</h2>
                  </ScrollAnimation>
                </div>

                <ScrollAnimation animateIn='flipInX'>
                <h3 class="sub_title_7sft mb-1">Talent Meets Diversity</h3>
                  </ScrollAnimation>
               

                <p>
                  We are at the forefront of the technology industry in Pakistan and continue to lead the market with our extraordinary team that relentlessly delivers an exceptional performance with their unflagging enthusiasm and quest for innovation.
                  Our work process thrives on diversity and we love to work with people from a varied personal and professional backgrounds. We cherish the fact that our team comes from different industries, geographies, and ethnic cultures.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* CONTENT */}


      <div className="section team-section ">
        <Container>
          <div className="team-wrap">
            {/* <div className="section-title text-center">
              <h3 className="sub-title"> Highly Professional Staffs </h3>
              <h2 className="title">  IT technology and technical fields professional staff </h2>
            </div> */}

            <div className="team-content-wrap">
              <Container>
                <Container>
                  <Row>
                    {employeesData.map((resEmp, i) => {
                      return (
                        <Col md={3} lg={3} xs={12} sm={12} key={i}>

                          <div className="single-team">
                            <div className="team-img">
                              <a href="#">
                                <img src={configUrl.APIImagesdURL + resEmp.Profile_Picture} alt="team1" />
                              </a>
                            </div>

                            <div className="team-content">
                              <div className="team-social">
                                <ul className="social">
                                  <li><FaLinkedinIn /></li>
                                </ul>
                              </div>
                              {/* <h3 className="name">{resEmp.Name}</h3> */}
                              <span className="designation">{resEmp.Designation}</span>
                            </div>
                            <div className="emp_des_7sft">
                              <p>{resEmp.Description}</p>
                            </div>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>

                  {/* <Row>
                    {employeesData.map((resEmp, i) => {
                      return (
                        <Col md={2} xl={2} lg={2} sm={12} xs={12}>

                          <div className="card-resume__photo">
                          <img src={resEmp.Profile_Picture} alt="team1" />
                          </div>
                        </Col>
                      )
                    })}

                  </Row> */}


                </Container>
              </Container>
            </div>
          </div>
        </Container>

      </div>

      <Footer />
    </>
  );
}

export default OurTeam;