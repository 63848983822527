import React from "react";
import Footer from "../../components/Footer";
import MainNavBar from "../../components/main_menu";
import { Container, Col, Row } from "react-bootstrap";
import { useState, useEffect } from 'react';

import configUrl from "../../config";
import ScrollAnimation from "react-animate-on-scroll";

function Consulting() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MainNavBar />
      <div className="page-banner-section services_backImage" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + '/images/services.jpg'})` }}>
        <div className="shape-banner2">

        </div>
        {/* <div className="shape-1"></div> */}
        <Container>
          <Container className="page-banner-wrap ">
            <Row>
              <Col>
                <div className="page-banner text-center">
                  <h2 className="title">Consulting</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <Container>
        <Row className="mt-5">
          <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
            <div className="about_content_7sft">
              <div className="section_title_7sft">
                <div class="section_title_7sft mb-3">

                  <ScrollAnimation animateIn='fadeIn'>
                    <h2 class="title_7sft">Consulting Services</h2>
                  </ScrollAnimation>


                </div>
                <ScrollAnimation animateIn='flipInX'>
                  <h3 class="sub_title_7sft mb-1">Transform Today To Bridge Tomorrow</h3>
                </ScrollAnimation>



                <p>
                  Running a seamless IT process is a demanding task, especially when you are a large enterprise. There are several cost and reputational risks at stake and every organization must ensure that its IT process aligns with business goals while complying with regulatory standards.<br /><br />
                  An effective IT process maximizes throughput, minimizes costs, and mitigates risks. Nonetheless, a lot of organizations fail to leverage the real value of IT functions and fall prey to cost overruns and regulatory injunctions. Our IT consultants have the right knowledge and insights to help you design, configure and optimize your organization’s IT ecosystem in a way that curtails the potential costs and compliance risks.
                </p>

                <ScrollAnimation animateIn='flipInX'>
                  <h3 class="sub_title_7sft mb-1">How Our Team Works?</h3>
                </ScrollAnimation>



                <p>
                  Our team of IT consultants is comprised of IT specialists, database analysts, enterprise architects, cloud professionals, and data security experts who can advise you on key areas of IT operations. They can design, build, optimize and scale your organization’s entire IT mechanism and enable transformative technologies across your technology stack to reduce technical debts, control costs, and improve cross-functional coordination. Moreover, our team can help you identify risks and criticalities within your data pipelines and put into place a watertight data security framework to keep threat actors out of the attack surface.
                </p>

                <ScrollAnimation animateIn='flipInX'>
                  <h3 class="sub_title_7sft" style={{ marginTop: '20px', marginBottom: '20px' }}>The Many Facets of Our Consulting Services</h3>
                </ScrollAnimation>




                <ul className="list_content">
                  <li> <h6 class="sub_title_7sft mb-1">Technology Integration</h6></li>
                </ul>
                <p>
                  Our team of IT consultants is comprised of IT specialists, database analysts, enterprise architects, cloud professionals, and data security experts who can advise you on key areas of IT operations. They can design, build, optimize and scale your organization’s entire IT mechanism and enable transformative technologies across your technology stack to reduce technical debts, control costs, and improve cross-functional coordination. Moreover, our team can help you identify risks and criticalities within your data pipelines and put into place a watertight data security framework to keep threat actors out of the attack surface.
                </p>

                <ul className="list_content">

                  <li><h6 class="sub_title_7sft mb-1">Bespoke IT Solutions</h6></li>

                </ul>
                <p>
                  An organization’s IT ecosystem is built on certain realities. However, not all businesses have the visibility to drill down those realities. Our consultants will collaborate with you to discover the application portfolio and their interdependencies and map out a viable strategy to integrate tailored IT solutions that conform to your organization’s current realities.
                </p>

                <ul className="list_content">

                  <li> <h6 class="sub_title_7sft mb-1">Cloud Migration</h6></li>

                </ul>
                <p>
                  COVID-19 has catalyzed cloud computing in current times. However, the cloud can impact your bottom line only when you understand the economics of cloud computing. Our team of cloud consultants and cloud engineers can assess your current workloads and provide you with deep insights into the application portfolio to single out applications that best fit into cloud architecture. We can also provide you with an end-to-end migration plan to accomplish a risk-free transition to the cloud.
                </p>

                <ul className="list_content">

                  <li> <h6 class="sub_title_7sft mb-1">Data Security Consultancy</h6></li>

                </ul>
                <p>
                  Inadequate data security measures can disrupt an organization’s internal and external business processes. Our data security experts can perform an in-depth risk assessment of your organization’s attack surface and identify current vulnerabilities. They can advise you on unaddressed security blind spots and deploy solutions to root out existing threats across the attack surface.
                </p>
              </div>
            </div>
          </Col>

          {/* <Col md={6} lg={6} xs={12} sm={12} className="mt-5">
            <div className="office_right_7sft">
            </div>
          </Col> */}
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default Consulting;