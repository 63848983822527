import { Container, Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import './Header.scss';
import MainNavBar from "./main_menu";
import { useEffect } from "react";
import configUrl from "../config";
import ScrollAnimation from "react-animate-on-scroll";


function Header() {

    useEffect(() => {
        // content
        var vid = document.getElementById("myVideo");
        vid.playbackRate = 0.8;

    }, []);
    return (
        <>
            <MainNavBar />
            <div className="banner">
                <div className="shape-1"></div>
                <Container>
                    <Row>
                        <Col md={6} lg={6} sm={12} xs={12}>
                            <ScrollAnimation animateIn='fadeIn'
                                >
                                <h5>An IT consulting firm specialized in</h5>

                                <h3>Consulting Services, Managed IT Services, Software Development and Cloud Migration</h3>
                                <div className="case-study-btn mt-3">
                                    <NavLink className="btn_7sft" to="/about-us">
                                        Read More
                                    </NavLink>
                                </div>
                            </ScrollAnimation>

                        </Col>
                        <Col md={6} lg={6} sm={12} xs={6}></Col>
                    </Row>
                    <div className="hero_video">
                        <video id="myVideo" width="100%" height="100%" className="video_class" loop muted autoPlay>
                            <source src={configUrl.ImgBaseUrl + "/images/video.mp4"} type="video/mp4" />
                        </video>
                    </div>

                </Container>
            </div>
        </>
    );
}

export default Header;