import React from 'react';
import {Col, Form, Row } from "react-bootstrap";
import '../../pages_scss/Login.scss';
import { useState } from 'react';
import axiosConfig from "../../axiosConfig"
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import configUrl from '../../config';

const Login = () => {
    const [username, setUserName] = useState()
    const [password, setPasssword] = useState();

const navigate = useNavigate();
    function onLogin(e) {
        e.preventDefault() 
        axiosConfig.post('/login', {
            email: username,
            password: password
        }).then((res) => {
            localStorage.setItem("storage", res.data.token);
            localStorage.setItem("userId", res.data.id);
            navigate('/panel')
            toast.info("Logged In!");
            if (res.data.token) {
                setUserName("");
                setPasssword("")
            }
        }).catch((e)=>{
            toast.info("Incorrect User or Password");
        })
    }

    function ClickFunc(e) {
        e.preventDefault();
        navigate('/email');
    }

   
    return (
        <>
            <div className="App-header ">
                <Form className="form_class Widget_widget__Card">
                    <Row>
                        <Col md={12} className='d-flex justify-content-center align-items-center mb-3'>
                            <img src={`${configUrl.ImgBaseUrl}/images/logo.png`} className="logo" />
                        </Col> 
                        <Col md={12} className='mb-3'>
                            <div className="single_input_7sft">
                                <Form.Control type="email" placeholder="Your Email" onChange={(e) => { setUserName(e.target.value) }} />
                            </div>
                        </Col>
                        <Col md={12} className='mb-3'>
                            <div className="single_input_7sft">
                                <Form.Control type="password" placeholder="Your Password" onChange={(e) => { setPasssword(e.target.value) }} />
                            </div>
                        </Col>
                        <h6 className="forget-btn" onClick={(e) => { ClickFunc(e) }}>Forgot Password?</h6>
                    </Row>
                    <button className="btn_log_7sft mb-3" onClick={(e) => { onLogin(e) }}>
                        Login
                    </button>
                </Form>

                <div className="case-study-btn text-center mt-3">
                </div>
            </div>
        </>
    );
};

export default Login;