import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { MDBDataTable } from "mdbreact";
import './panel.scss'
import web_services from '../../web_services/web_services';
import { FaEdit } from "react-icons/fa";
import { FaTrashRestoreAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosConfig from "../../axiosConfig";
import configUrl from '../../config';
import { Rings } from 'react-loader-spinner'

function EmployeeDirectory() {
    const [loaderTime, setloaderTime] = useState(false);
    const [EName, setEName] = useState();
    const [EDepartment, setEDepartment] = useState();
    const [EDesignation, setEDesignation] = useState();
    const [EDes, setEDes] = useState();
    const [ELProfile, setELProfile] = useState();
    const [EProfilePic, setEProfilePic] = useState();
    const [EmpId, setEmpId] = useState();
    const [employees, setEmployees] = useState([]);
    const [jobsTF, setjobsTF] = useState(false);

    const [NameError, setNameErr] = useState("");
    const [DeptError, setDeptErr] = useState("");
    const [DesigError, setDesigErr] = useState("");
    const [LPError, setLPErr] = useState("");
    const [PicError, setPicErr] = useState("");
    const [DescError, setDescErr] = useState("");

    const [show, setShow] = useState(false);
    var [delItem, setdelItem] = useState(false);
    var [deleteId, setdeleteId] = useState();

    useEffect(() => {
        web_services.getEmployees().then((res) => {
            setEmployees(res.data.employees ? res.data.employees : [])
            setjobsTF(false)
        })
    }, [jobsTF])

    const validate = () => {

        let NameValid = false;
        let DeptValid = false;
        let DesigValid = false;
        let PicValid = false;
        let DescValid = false;
        let LPValid = false;

        if (EName == "" || EName == undefined || EName.trim() == "") {
            setNameErr("Please Enter a Name");
            NameValid = false;
        }
        else {
            setNameErr("");
            NameValid = true;
        }

        if (EDepartment == "" || EDepartment == undefined || EDepartment.trim() == "") {
            setDeptErr("Please Enter a Department");
            DeptValid = false;
        }
        else {
            setDeptErr("");
            DeptValid = true;
        }

        if (EDesignation == "" || EDesignation == undefined || EDesignation.trim() == "") {
            setDesigErr("Please Enter Designation");
            DesigValid = false;
        }
        else {
            setDesigErr("");
            DesigValid = true;
        }

        if (ELProfile == "" || ELProfile == undefined || ELProfile.trim() == "") {
            setLPErr("Please Enter Linkedin Profile");
            LPValid = false;
        }
        else {
            setLPErr("");
            LPValid = true;
        }

        if (EDes == "" || EDes == undefined || EDes.trim() == "") {
            setDescErr("Please Enter Description");
            DescValid = false;
        }
        else {
            setDescErr("");
            DescValid = true;
        }

        if (EProfilePic == "" || EProfilePic == null || EProfilePic == undefined) {
            setPicErr("Attach an Image");
            PicValid = false;
        }
        else {
            setPicErr("");
            PicValid = true;
        }


        if (NameValid && DeptValid && DesigValid && LPValid && DescValid && PicValid) {
            return true;
        }
        else {
            return false;
        }
    }

    const onSubmitEmpolyee = (e) => {
        e.preventDefault();
        setloaderTime(true)
        let isvalidate = validate();
        if (isvalidate) {
            let formData = new FormData();
            formData.append('Name', EName);
            formData.append('Department', EDepartment);
            formData.append('Designation', EDesignation);
            formData.append('Description', EDes);
            formData.append('Linkedin_Profile', ELProfile);
            formData.append('Profile_Picture', EProfilePic);

            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("storage")}`,
                    'content-type': 'multipart/form-data'
                },
            }

            if (!EmpId) {
                axiosConfig.post("/addEmployee", formData, config)
                    .then(response => {
                        setloaderTime(false)
                        setjobsTF(true);
                        setEName("");
                        setEDepartment("");
                        setEDesignation("");
                        setEDes("");
                        setELProfile("");
                        document.getElementById("exampleFormControlFile1").value = "";
                        setEProfilePic("");
                        toast.success("Employee Successfully Added!");
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {

                axiosConfig.post("/updateEmployee" + "/" + EmpId, formData, config)
                    .then(response => {
                        setloaderTime(false)
                        setjobsTF(true);
                        setEName("");
                        setEDepartment("");
                        setEDesignation("");
                        setEDes("");
                        setELProfile("");
                        document.getElementById("exampleFormControlFile1").value = "";
                        setEProfilePic("");
                        toast.success("Employee Successfully Updated!");
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }

    const deleteEmployee = (Id) => {
        setdeleteId(Id)
        setShow(true)
    }

    const updateEmployee = (id) => {
        document.getElementById("exampleFormControlFile1").value = "";
        setEmpId(id)
        web_services.getEmployeeByID(id).then((res) => {
            let upData = res.data.employee[0];
            setEName(upData.Name);
            setEDepartment(upData.Department);
            document.getElementById("exampleFormControlFile1").title = upData.image;
            setEDesignation(upData.Designation);
            setEDes(upData.Description);
            setELProfile(upData.Linkedin_Profile);
        })
    }

    const handleClose = () => setShow(false);
    const ConfirmDelete = () => {
        delItem = true
        if (delItem === true) {

            web_services.EmployeeDelete(deleteId).then(() => {
                setEmployees(employees.filter(com => com.id !== deleteId));
                setjobsTF(true)
                toast.error("Employee Deleted!");
                setShow(false)
            })
        }

    }

    const fileUploader = (e) => {
        console.log(e.target.files[0].type)
        if (e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/jpg") {
            setEProfilePic(e.target.files[0]);
            setPicErr("")
        } else {
            setPicErr("File Type Not Supported");
            document.getElementById("exampleFormControlFile1").value = "";
        }

    }

    return (
        <>
            {loaderTime ? <div className="loader_submit">
                <Rings color="#005de0" />
            </div> : null}
            <Container className="log_width_container">
                <Form >
                    <Row className='d-flex justify-content-center'>
                        <Col md={8} sm={12} lg={8} xl={8}>
                            <Row>
                                <Col md={6}>
                                    <div className="single_input_7sft">
                                        <Form.Control type="text" placeholder="Name" value={EName} onChange={(e) => { setEName(e.target.value) }} />
                                        <div style={{ color: 'red' }}>{NameError}</div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="single_input_7sft">
                                        <Form.Control type="text" placeholder="Department" value={EDepartment} onChange={(e) => { setEDepartment(e.target.value) }} />
                                        <div style={{ color: 'red' }}>{DeptError}</div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="single_input_7sft">
                                        <Form.Control type="text" placeholder="Designantion" value={EDesignation} onChange={(e) => { setEDesignation(e.target.value) }} />
                                        <div style={{ color: 'red' }}>{DesigError}</div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="single_input_7sft">
                                        <Form.Control type="text" placeholder="Linkedin Profile Url" value={ELProfile} onChange={(e) => { setELProfile(e.target.value) }} />
                                        <div style={{ color: 'red' }}>{LPError}</div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="single_input_7sft">
                                        <input type="file" title="Upload Image" accept=".jpeg,.jpg,.png" class="form-control" id="exampleFormControlFile1" onChange={(e) => { fileUploader(e) }} />
                                        <div style={{ color: 'red' }}>{PicError}</div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="single_input_7sft">
                                        <Form.Control as="textarea" rows={5} placeholder="Write A Description" value={EDes} onChange={(e) => { setEDes(e.target.value) }} />
                                        <div style={{ color: 'red' }}>{DescError}</div>
                                    </div>
                                </Col>
                                <Col md={12} className='mt-3 d-flex justify-content-end'>
                                    <button className="btn_log_7sft mb-3" type='button' onClick={onSubmitEmpolyee}>
                                        Submit
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>

                <MDBDataTable
                    striped
                    bordered
                    small
                    className="text-center"
                    noBottomColumns
                    data={{
                        columns: [
                            {
                                label: "Name",
                                field: "Name",
                            },
                            {
                                label: "Department",
                                field: "Department",
                            },
                            {
                                label: "Designation",
                                field: "Designation",
                            },
                            {
                                label: "Description",
                                field: "Description",
                            },
                            {
                                label: "Date",
                                field: "Date",
                            },
                            {
                                label: "Profile_Picture",
                                field: "Profile_Picture",

                            },
                            {
                                label: "Actions",
                                field: "action",
                            },
                        ],
                        rows: employees.map((resE) => ({
                            Name: resE.Name,
                            Department: resE.Department,
                            Designation: resE.Designation,
                            Date: new Date(resE.Date).toDateString(),
                            Profile_Picture: (
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={configUrl.APIImagesdURL + resE.Profile_Picture} style={{ width: "100px", height: "120px" }} />
                                </div>

                            ),
                            Description: resE.Description,
                            action: (
                                <div className="d-flex justify-content-center align-items-center">
                                    <FaEdit
                                        title="Edit"
                                        className="action_button"
                                        style={{ marginRight: '7px', cursor: 'pointer' }}
                                        onClick={() => updateEmployee(resE.id)}
                                    />

                                    <FaTrashRestoreAlt
                                        title="Delete"
                                        className="action_button icons_Color"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => deleteEmployee(resE.id)}
                                    />

                                </div>
                            ),
                        })),
                    }}
                />
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you Sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you really want to delete this record?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="danger"  onClick={ConfirmDelete}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>



        </>
    )
}
export default EmployeeDirectory;