import React from "react";
import Footer from "../../components/Footer";
import MainNavBar from "../../components/main_menu";
import { Container, Col, Row } from "react-bootstrap";
import { useState, useEffect } from 'react';
import '../../pages_scss/Sybase.scss';
import configUrl from "../../config";
import ScrollAnimation from "react-animate-on-scroll";


function Cloud() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MainNavBar />
      <div className="page-banner-section services_backImage" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + '/images/services.jpg'})` }}>
        <div className="shape-banner2">

        </div>
        {/* <div className="shape-1"></div> */}
        <Container>
          <Container className="page-banner-wrap ">
            <Row>
              <Col>
                <div className="page-banner text-center">
                  <h2 className="title">Cloud Migration</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <Container>
        <Row className="mt-5">
          <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
            <div className="about_content_7sft">
              <div className="section_title_7sft">
                <div class="section_title_7sft mb-3">

                  <ScrollAnimation animateIn='fadeIn'>
                    <h2 class="title_7sft">Cloud Migration</h2>
                  </ScrollAnimation>



                </div>

                <ScrollAnimation animateIn='flipInX'>
                  <h3 class="sub_title_7sft mb-1">Sail Through The Complexities of Cloud Migration With 7nSoft</h3>
                </ScrollAnimation>



                <p>
                  We live in a time and age where the cloud has opened new avenues for businesses. The hyperscale architecture of the cloud has unleashed the power of scalable computing and enabled organizations to move their businesses beyond the boundaries of on-premise data centers.<br /><br />
                  Cloud drives modernization and innovation in the corporate world and allows enterprises to reimagine their businesses, products, and services in a digital age. Today, cloud is the most viable computing model businesses can bet on to sustain the financial and operational challenges in a post-pandemic world.
                </p>

                <ScrollAnimation animateIn='flipInX'>
                  <h3 class="sub_title_7sft mb-1">Partner With 7nSoft To Unlock Real Value of Cloud</h3>
                </ScrollAnimation>


                <p>
                  Cloud migration is a complex process and many organizations miss the mark when undergoing this transformative process. 7nSoft has helped many businesses successfully execute this business-changing transition. We’ve helped hundreds of small and big companies Cloud build and transform their businesses using the cloud as the technology platform.<br /><br />
                  Our proven capabilities in cloud migration have enabled 199 companies to accelerate their businesses by leveraging innovative cloud technologies. We have enabled seamless cloud integration using our premier cloud migration tools. Some of the projects have resulted in major success stories and allowed our customers to unlock the true value of the cloud leveraging its core selling points - scalability, flexibility, low-cost pricing model, etc.
                </p>


                <ScrollAnimation animateIn='flipInX'>
                  <h3 class="sub_title_7sft mb-1">Our Capabilities</h3>
                </ScrollAnimation>


                <p>
                  We converge businesses, and industry solutions with cloud technologies to help organizations achieve their strategic goals – cost control, fast service delivery, and revenue growth. We assist enterprises in sailing through the complexities of the cloud journey and help them restructure and rearchitect their business model on cloud infrastructure. Under the trusted leadership of our team of consultants, cloud architects, cloud engineers, and application coders, we’ve helped hundreds of clients across industries to deploy their business model on the cloud.<br /><br />
                  Here are some of the unsurpassed capabilities that set us apart from our competitors:
                </p>

                <ul className="list_content">

                  <li> <h6 class="sub_title_7sft mb-1">Assessment</h6></li>

                </ul>
                <p>
                  Application discovery is the first step towards cloud adoption. We take a deep dive into our client’s application portfolio to understand existing complexities based on applications’ dependencies. Our team leaves no stone unturned in the assessment process to get an accurate picture of the client’s environment.
                </p>

                <ul className="list_content">

                  <li> <h6 class="sub_title_7sft mb-1">Migration Strategy</h6></li>

                </ul>
                <p>
                  We construct an end-to-end migration plan after a complete application discovery. We single out applications looking into their inherent complexities and then set a migration strategy - Rearchitecting, Replatforming, Refactoring - according to their cloud-readiness score.
                </p>

                <ul className="list_content">

                  <li> <h6 class="sub_title_7sft mb-1">Execution</h6></li>

                </ul>
                <p>
                  We take a customer-centric approach to rebuild our client’s applications and workloads on the cloud. We provide holistic cloud solutions to our clients to help them deploy their applications and databases on their choice of cloud deployment model – Private, Public and Hybrid.
                </p>

                <ScrollAnimation animateIn='flipInX'>
                  <h3 class="sub_title_7sft mb-1">A Trusted Partner of Major Cloud Service Providers</h3>
                </ScrollAnimation>



                <p>
                  We are a highly-ranked cloud migration partner of leading Cloud Service Providers including AWS, Microsoft Azure, and Google Cloud. Our industry-specific cloud solutions have served clients from major industries including healthcare, manufacturing, transportation and logistics, education, entertainment, retail, banking & finance, and the public sector.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default Cloud;