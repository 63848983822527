import React from 'react';
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { MDBDataTable } from "mdbreact";
import './panel.scss'
import web_services from '../../web_services/web_services';
import { FaTrashRestoreAlt, FaWindowClose} from "react-icons/fa";
import { toast } from "react-toastify";
import axiosConfig from "../../axiosConfig";
import { FaEdit } from "react-icons/fa";
import configUrl from '../../config';
import { Rings } from 'react-loader-spinner'


function AddEvent() {
    const [loaderTime, setloaderTime] = useState(false);
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [eventImages, setEventImages] = useState([]);
    const [eventDates, setEventDate] = useState([]);
    const [Events, setEvents] = useState([]);

    const [updateEventImages, setUpdateEventImages] = useState([]);

    const [TitleError, setTitleError] = useState("");
    const [DateError, setDateError] = useState("");
    const [DesError, setDesError] = useState("");
    const [ImagesError, setImagesError] = useState("");
    const [eventId,SetEventId] = useState()

    const [eventSeter, seteventSeter] = useState(false);

    const [show, setShow] = useState(false);
    var [delItem, setdelItem] = useState(false);
    var [deleteId, setdeleteId] = useState();

    const [showImg, setShowImg] = useState(false);
    var [delItemImg, setdelItemImg] = useState(false);
    var [deleteIdImg, setdeleteIdImg] = useState();

    const [file, setfile] = useState([]);


    let deletedImages = [];

    useEffect(() => {
        web_services.getEvents().then((resEvent) => {
            setEvents(resEvent.data.events ? resEvent.data.events : []);
            seteventSeter(false)
        })
    }, [eventSeter]);

    useEffect(() => {
        console.log("hello", eventImages)
    }, [eventImages]);

    // const validate = () => {
    //     let TitleValid = false;
    //     let DateValid = false;
    //     let DescriptionValid = false;
    //     let ImagesValid = false;

    //     if (title == "" || title == undefined || title.trim() == "") {
    //         setTitleError("Please Enter a Title");
    //         TitleValid = false;
    //     }
    //     else {
    //         setTitleError("");
    //         TitleValid = true;
    //     }

    //     if (eventDates == "" || eventDates == undefined || eventDates.trim() == "") {
    //         setDateError("Please Enter a Date");
    //         DateValid = false;
    //     }
    //     else {
    //         setDateError("");
    //         DateValid = true;
    //     }

    //     if (description == "" || description == undefined || description.trim() == "") {
    //         setDesError("Please Enter Description");
    //         DescriptionValid = false;
    //     }
    //     else {
    //         setDesError("");
    //         DescriptionValid = true;
    //     }

    //     if (eventImages == "" || eventImages == null || eventImages == undefined) {
    //         setImagesError("Attach Images");
    //         ImagesValid = false;
    //     }
    //     else {
    //         setImagesError("");
    //         ImagesValid = true;
    //     }


    //     if (TitleValid && DateValid && DescriptionValid && ImagesValid) {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

  const submitEvent = async(e) => {
        e.preventDefault();
        setloaderTime(true)
        // let isvalidate = validate();
       // if (isvalidate) {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("storage")}`,
                    'content-type': 'multipart/form-data'
                },
            }
          
            let formData = new FormData();
            for (let i = 0; i < updateEventImages.length; i++) {
                formData.append('ima', updateEventImages[i]);
            }
            formData.append('Title', title);
            formData.append('Description', description);
            formData.append('Date', eventDates);   
            
            if(!eventId){
                for (let i = 0; i < eventImages.length; i++) {
                    console.log(eventImages[i])
                    formData.append('images', eventImages[i]);
                }
                console.log("hello event",formData)
                axiosConfig.post("/addEvent", formData, config)
                .then(response => {
                    setloaderTime(false)
                    setTitle("");
                    seteventSeter(true)
                    setDescription("");
                    setEventDate("")
                    document.getElementById("eveImages").value = "";
                    toast.success("Event Successfully Added!");
                })

            }else{
                let updatedEventImages = [];
                for (let i = 0; i < eventImages.length; i++) {
                    updatedEventImages.push(eventImages[i])     
                }
                let mergedArray = updateEventImages.concat(updatedEventImages)
                for (let i = 0; i < mergedArray.length; i++) {
                    formData.append('images', mergedArray[i]);   
                }

                console.log("hello event",mergedArray)
                await axiosConfig.post("/updateEvent" + '/'+ eventId, formData, config)
                .then(response => {
                    setloaderTime(false)
                    setTitle("");
                    seteventSeter(true)
                    setDescription("");
                    setEventDate("");
                    setUpdateEventImages([]);
                    document.getElementById("eveImages").value = "";
                    toast.success("Event Successfully Updated!");
                })
          //  }
          
     }

    }

    const deleteEmployee = (Id) => {
        setdeleteId(Id)
        setShow(true)
    }

    const handleClose = () => setShow(false);
    const ConfirmDelete = () => {
        delItem = true
        if (delItem === true) {

            web_services.DeleteEvents(deleteId).then(() => {
                setEvents(Events.filter(com => com.id !== deleteId));
                seteventSeter(true)
                toast.error("Event Deleted!");
                setShow(false)
            })
        }

    }

    const fileUploader = (e) => {
        console.log("Test cons:", e.target.files);
        if (e.target.files[0].type == "video/mp4" || e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/jpg") {
            setEventImages(e.target.files);
            setImagesError("");

            var fileObj = [];
            var fileArray = [];
            fileObj.push(e.target.files);
            for (let i = 0; i < fileObj[0].length; i++) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]));
            }
            console.log(fileArray);
            setfile(fileArray);
        } else {
            setImagesError("File Type Not Supported")
            document.getElementById("eveImages").value = "";
        }

    }

    const getEmployeeById = (id) => {
        SetEventId(id)
        web_services.getEventById(id).then((resEvent) => {
            let data = resEvent.data.event[0];
            setTitle(data.Title);
            setDescription(data.Description);
            setEventDate(data.Date);

            let imagesByIds = [];
            for (var i = 0; i < data.images.length; i++) {
                if(data.images[i] !== ""){
                    imagesByIds.push(data.images[i])
                }else{
                    console.log("there was some error")
                }
            }
            setUpdateEventImages(imagesByIds)
        })
    }


    const deleteImg = (Id) => {
        setdeleteIdImg(Id)
        setShowImg(true)
    }

    const handleCloseImg = () => setShowImg(false);
    const ConfirmDeleteImg = () => {
        const edit = updateEventImages.filter((item, index) => item !== deleteIdImg);
        let getImageNameOnly = deleteIdImg.replace("Events/", "")
        setUpdateEventImages(edit)
        delItemImg = true

        if (delItemImg === true) {
            web_services.DeleteEventImages(getImageNameOnly).then((res)=>{
                console.log('deleted',res.data)
                setShowImg(false)
            })
        }

    }

   const resetForm =()=>{
    setloaderTime(false)
    setTitle("");
    seteventSeter(true)
    setDescription("");
    setEventDate("");
    setUpdateEventImages([]);
    document.getElementById("eveImages").value = "";
    }
    

    return (

        <>
            {loaderTime ? <div className="loader_submit">
                <Rings color="#005de0" />
            </div> : null}

            <Container className="log_width_container">
                <Form >
                    <Row className='d-flex justify-content-center'>
                        <Col md={8} sm={12} lg={8} xl={8}>
                            <Row>
                                <Col md={6}>
                                    <div className="single_input_7sft">
                                        <Form.Control type="text" placeholder="Name" value={title} onChange={(e) => { setTitle(e.target.value) }} />
                                        <div style={{ color: 'red' }}>{TitleError}</div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="single_input_7sft">
                                        <input type="file" title="Upload Image" accept=".jpeg,.jpg,.png,.mp4,.avi,.mpg" class="form-control" id="eveImages" multiple onChange={(e) => { fileUploader(e) }} />
                                        <div style={{ color: 'red' }}>{ImagesError}</div>
                                    </div>
                                </Col>
                                <Col>
                                
                                <Row className='image_veiw'>
                                {updateEventImages.map((res, i) => {
									res=res.toLowerCase();
                                    return (
                                        <div className='image_conatiner'>
                                            { res.slice(-3) === 'mp4' ?  <video className='imgUpload mt-4' controls> <source src={configUrl.APIImagesdURL + res} type="video/mp4"/></video>:''}
                                            { res.slice(-3) === 'jpg' || res.slice(-3) === 'png' ||  res.slice(-4) === 'jpeg' || res.slice(-3) === 'bmp' || res.slice(-4) === 'jfif' || res.slice(-4) === 'tfif'  || res.slice(-3) === 'gif' ?  <img className='imgUpload mt-4' src={configUrl.APIImagesdURL + res} alt="..." />:''}
                                            <FaWindowClose onClick={() => deleteImg(res)} className="remove-button"/>
                                        </div>   
                                    )
                                })}
                                </Row>
                                </Col>
                                <Col md={12}>
                                    <div className="single_input_7sft">
                                        <input type="date" title="Date" class="form-control" id="eventDates" value={eventDates} onChange={(e) => { setEventDate(e.target.value) }} />
                                        <div style={{ color: 'red' }}>{DateError}</div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="single_input_7sft">
                                        <Form.Control as="textarea" rows={5} placeholder="Write A Description" value={description} onChange={(e) => { setDescription(e.target.value) }} />
                                        <div style={{ color: 'red' }}>{DesError}</div>
                                    </div>
                                </Col>
                                <Col md={12} className='mt-3 d-flex justify-content-end'>
                                    <button className="btn_log_7sft" style={{marginRight:"8px"}} type='button' onClick={resetForm}>
                                        Reset
                                    </button>

                                    <button className="btn_log_7sft mb-3" type='button' onClick={submitEvent}>
                                        Submit
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>

                <MDBDataTable
                    striped
                    bordered
                    small
                    className="text-center"
                    noBottomColumns
                    data={{
                        columns: [
                            {
                                label: "Event Title",
                                field: "title",
                            },
                            {
                                label: "Event Date",
                                field: "date",
                            },
                            {
                                label: "Action",
                                field: "action",
                            },
                        ],
                        rows: Events.map((resE) => ({
                            title: resE.Title,
                            date: resE.Date,
                            phone: resE.Phone,
                            action: (
                                <>
                                    <FaEdit
                                        title='Edit'
                                        className="action_button icons_Color"
                                        style={{ cursor: 'pointer', marginRight: '10px' }}
                                        onClick={() => getEmployeeById(resE.id)} />

                                    <FaTrashRestoreAlt
                                        title="Delete"
                                        className="action_button icons_Color"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => deleteEmployee(resE.id)}
                                    />
                                </>
                            ),
                        })),
                    }}
                />

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you Sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you really want to delete this record?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="danger" onClick={ConfirmDelete}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showImg} onHide={handleCloseImg}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you Sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you really want to delete this image?</Modal.Body>
                    <Modal.Footer>
                        <Button  variant="primary" onClick={handleCloseImg}>
                            Close
                        </Button>
                        <Button variant="danger" onClick={ConfirmDeleteImg}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>
    )
}

export default AddEvent;