import React from "react";
import Footer from "../../components/Footer";
import MainNavBar from "../../components/main_menu";
import { Container, Col, Row } from "react-bootstrap";
import { useState, useEffect } from 'react';

import configUrl from "../../config";
import ScrollAnimation from "react-animate-on-scroll";

function Managed() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MainNavBar />
      <div className="page-banner-section services_backImage" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + '/images/services.jpg'})` }}>
        <div className="shape-banner2">

        </div>
        {/* <div className="shape-1"></div> */}
        <Container>
          <Container className="page-banner-wrap ">
            <Row>
              <Col>
                <div className="page-banner text-center">
                  <h2 className="title">Managed IT Services</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <Container>
        <Row className="mt-5">
          <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
            <div className="about_content_7sft">
              <div className="section_title_7sft">
                <div class="section_title_7sft mb-3">
                  <ScrollAnimation animateIn='fadeIn'>
                    <h2 class="title_7sft">Managed IT Services</h2>
                  </ScrollAnimation>


                </div>

                <ScrollAnimation animateIn='flipInX'>
                  <h2 class="sub_title_7sft">Get More Value At Less Price</h2>
                </ScrollAnimation>



                <p>
                  Enterprises across the globe constantly face recurring issues in their IT process. But not all of them have the resources to battle those pressing issues. This leads to an inefficient IT environment which allows technical debts to accumulate over time and consequently backlogs of issues start to roll in.<br /><br />
                  Managed IT services is one way organizations can avoid costly technical debts, streamline their IT process, and control costs through better, faster, and cost-efficient solutions. A managed IT service acts as a third-party Managed Service Provider (MSP) which can help enterprises orchestrate their workloads to support business processes and accelerate service delivery. A managed IT service provider deploys human resources who work in conjunction with an organization’s internal team to support IT processes and resolve day-to-day issues. In a fast-paced business environment, an MSP can play an important role to ramp up IT functions by deploying timely maintenance and support services
                </p>

                <ScrollAnimation animateIn='flipInX'>
                  <h2 class="sub_title_7sft">Our Managed IT Services Fit Every Business Model</h2>
                </ScrollAnimation>



                <p>
                  At 7nSoft, we help businesses lacking the right IT resource and skillset to tackle complex applications and workloads. We augment an organization’s in-house IT frameworks by providing technical and technological support using our manpower, technologies, and systems. Our tailored IT solutions can help enterprises shore up their internal and external IT business functions and boost their operational efficiency.<br /><br />
                  We offer comprehensive IT solutions ranging from system optimization/configuration and database management to cloud deployment and data security. Our extensive services are designed to meet the demands of heterogeneous systems working in on-premise datacenter and hybrid, private or public cloud platforms. We can also help enterprises build bare metal, virtual systems, and data security frameworks from the ground up in a private or multicloud environment.
                </p>

                <ScrollAnimation animateIn='flipInX'>
                  <h6 class="sub_title_7sft mb-1" style={{ marginTop: '20px', marginBottom: '20px' }}>Salient Features of Our Managed IT Services</h6>
                </ScrollAnimation>

                <ul className="list_content">

                  <li> <h6 class="sub_title_7sft mb-1">Full-Fledged IT Services and Support</h6></li>

                </ul>
                <p>
                  We have the top-rated manpower and premium technologies to provide technical IT support to businesses large and small. Our unmatched IT solutions can resolve underlying issues in any application environment regardless of size and complexity. We have clients across varied types of businesses including healthcare, manufacturing, retail, banking & finance, transportation and logistics, education, entertainment, and public sector to name a few.
                </p>


                <ul className="list_content">

                  <li> <h6 class="sub_title_7sft mb-1">An All-Star Team of IT Personnel</h6></li>

                </ul>
                <p>
                  We are an industry leader in IT services. We’ve been operating in the IT industry for more than [X] years and credit our longevity to our top-performing team comprised of supremely talented and skilled consultants, IT Administrators, DBAs, enterprise architects, and cloud engineers who have the exposure and experience of working with the biggest corporations in Pakistan and abroad.
                </p>

                <ul className="list_content">

                  <li> <h6 class="sub_title_7sft mb-1">24/7/365 Helpdesk Support</h6></li>

                </ul>
                <p>
                  We provide round-the-clock helpdesk support to help our clients get timely resolution of their critical issues. Whether you need active technical support for networks, applications, and servers or want 24/7 database administration services, you can fall back on our helpdesk service to remediate the most urgent issues using our escalation and remediation process.
                </p>

                <ul className="list_content">

                  <li> <h6 class="sub_title_7sft mb-1">Foolproof Data Security</h6></li>

                </ul>
                <p>
                  We prioritize the data security of our clients at every step. Our IT solutions are carefully designed to ensure that clients’ data remain safe and secure within the unbreakable security walls. We deploy fault-tolerant backup storage to make sure that our client’s data doesn’t get affected during a major remediation process or when restoring systems after a disruptive event. Rest assured, our IT services comply with standard procedures mandated by regulatory bodies.
                </p>

                <ul className="list_content">

                  <li> <h6 class="sub_title_7sft mb-1">Cost-Friendly IT Solutions</h6></li>

                </ul>
                <p>
                  Our high-value MSP services subtract the operating expenses and overheads businesses incur in a traditional in-house IT service. Our outsourcing model frees you from the cumulative costs of staff salaries, fringe benefits, and medical coverage. Moreover, we save businesses from additional manhours wasted on recurring issues which often lead to staff being overpaid.
                </p>

              </div>
            </div>

          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default Managed;