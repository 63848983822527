import React from 'react';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import '../../pages_scss/Login.scss';
import { useState } from 'react';
import axiosConfig from "../../axiosConfig"
import { toast } from "react-toastify";
import configUrl from '../../config';

 
const Email = () => {
    const [email, setEmail] = useState()

    function onSubmit(e) {
        e.preventDefault()
        axiosConfig.post('/resetPasswordEmail', {
            email: email,
        }).then((res) => {
            console.log(res);
                toast.info(res.data.message);
                setEmail('');
        })
    }

    return (
        <>
            <div className="App-header ">
                <Form className="form_class Widget_widget__Card">
                    <Row>
                        <Col md={12} className='d-flex justify-content-center align-items-center mb-3'>
                            <img src={`${configUrl.ImgBaseUrl}/images/logo.png`} className="logo" />
                        </Col>
                        <Col md={12} className='mb-3'>
                            <div className="single_input_7sft">
                                <Form.Control type="email" placeholder="Your Email" onChange={(e) => { setEmail(e.target.value) }} />
                            </div>
                        </Col>
                      
                    </Row>
                    <button className="btn_log_7sft mb-3" onClick={(e) => { onSubmit(e) }}>
                        Submit
                    </button>
                </Form>

                <div className="case-study-btn text-center mt-3">
                </div>
            </div>
        </>
    );
};

export default Email;