import React from "react";
import MainNavBar from "../components/main_menu";
import Footer from "../components/Footer";
import { Container, Col, Row, Form } from "react-bootstrap";
import { useState, useEffect } from 'react';
import '../pages_scss/ContactUs.scss';
import { FiPhoneCall } from 'react-icons/fi';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { FaHome } from "react-icons/fa"
import web_services from "../web_services/web_services";

import { toast } from "react-toastify";
import configUrl from "../config";
import ClientCaptcha from "react-client-captcha"
import ScrollAnimation from "react-animate-on-scroll";




function ContactUs() {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Subject, setSubject] = useState("");
  const [Phone, setPhone] = useState("");
  const [Des, setDes] = useState("");
  const [Capt, setCapt] = useState("");
  const [SubCapt, setSubCapt] = useState("");

  const [nameError, setNameErr] = useState("");
  const [emailError, setEmailErr] = useState("");
  const [subjectError, setSubjectErr] = useState("");
  const [phoneError, setPhoneErr] = useState("");
  const [DesError, setDesErr] = useState("");

  const [CaptchaError, setCaptchaErr] = useState("");


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const validate = () => {

    let NameValid = false;
    let EmailValid = false;
    let SubjectValid = false;
    let PhoneValid = false;
    let DesValid = false;
    let CaptchaValid = false;

    if (Name == "" || Name.trim() == "") {
      setNameErr("Please Enter a Name");
      NameValid = false;
    }
    else {
      setNameErr("");
      NameValid = true;
    }

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (Email == "" || Email.trim() == "") {
      setEmailErr("Please Enter an Email");
      EmailValid = false;
    }
    else if (!pattern.test(Email)) {
      setEmailErr("Invalid Email");
      EmailValid = false;
    }
    else {
      setEmailErr("");
      EmailValid = true;
    }

    if (Subject == "" || Subject.trim() == "") {
      setSubjectErr("Please Enter a Subject");
      SubjectValid = false;
    }
    else {
      setSubjectErr("");
      SubjectValid = true;
    }

    if (Phone == "" || Phone.trim() == "" || Phone.length != "11") {
      setPhoneErr("Please Enter a Valid Number");
      PhoneValid = false;
    }
    else {
      setPhoneErr("");
      PhoneValid = true;
    }

    if (Des == "" || Des.trim() == "") {
      setDesErr("Please Enter a Message");
      DesValid = false;
    }
    else {
      setDesErr("");
      DesValid = true;
    }

    if (Capt === SubCapt) {
      setCaptchaErr("");
      CaptchaValid = true;
    }
    else {
      setCaptchaErr("Invalid Captcha");
      CaptchaValid = false;
    }

    if (NameValid && EmailValid && SubjectValid && PhoneValid && DesValid && CaptchaValid) {
      return true;
    }
    else {
      return false;
    }
  }
  const submitContact = (e) => {

    let isvalidate = validate();
    if (isvalidate) {
      // if (Capt === SubCapt) {
      let data = {
        name: Name,
        email: Email,
        phone: Phone,
        subject: Subject,
        message: Des
      }
      web_services.AddContact(data).then((res) => {
        document.querySelector('#retryButton').click()
        toast.info("Message Sent!");
        setName("");
        setEmail("");
        setSubject("");
        setPhone("");
        setDes("");
        setSubCapt("");
      });
      // } else {
      //   toast.error("Captcha Failed!");
      // }
    }

  }

  const captchaHandler = (cap) => {
    setCapt(cap);
  }

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      submitContact();
    }
  };

  return (
    <>
      <MainNavBar />

      <div className="page-banner-section contact_backImage" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + 'images/contact.jpg'})` }}>
        <div className="shape-banner2">

        </div>
        {/* <div className="shape-1"></div> */}
        <Container>
          <Container className="page-banner-wrap">
            <Row>
              <Col>
                <div className="page-banner text-center">
                  <h2 className="title">Contact Us</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>


      <div className="contact_form_section section_margin">
        <Container>
          <div className="contact_wrap_7sft">
            <Row className="justify-content-center">
              <Col md={8} lg={8} sm={12} xs={12} xl={8} className="sect_margin">
                <div className="form_title_7sft">
                  < >
                    <ScrollAnimation animateIn='fadeIn'>
                      <h3 className="title">
                        Get Support from our Team
                      </h3>
                    </ScrollAnimation>

                  </>
                </div>

                <div className="contact_form_wrap_7sft">
                  <Row>
                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Label>Name <i style={{ color: 'red' }}>*</i></Form.Label>
                        <Form.Control type="text" value={Name} onChange={(e) => setName(e.target.value)} />
                        <div style={{ color: 'red' }}>{nameError}</div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Label>E-mail<i style={{ color: 'red' }}>*</i></Form.Label>
                        <Form.Control type="text" value={Email} onChange={(e) => setEmail(e.target.value)} />
                        <div style={{ color: 'red' }}>{emailError}</div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Label>Subject<i style={{ color: 'red' }}>*</i></Form.Label>
                        <Form.Control type="text" value={Subject} onChange={(e) => setSubject(e.target.value)} />
                        <div style={{ color: 'red' }}>{subjectError}</div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Label>Number<i style={{ color: 'red' }}>*</i></Form.Label>
                        <Form.Control type="number" value={Phone} onChange={(e) => setPhone(e.target.value.slice(0, 11))} />
                        <div style={{ color: 'red' }}>{phoneError}</div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="single_input_7sft">
                        <Form.Label>Message<i style={{ color: 'red' }}>*</i></Form.Label>
                        <Form.Control as="textarea" rows={5} value={Des} onChange={(e) => setDes(e.target.value)} />
                        <div style={{ color: 'red' }}>{DesError}</div>
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="d-flex align-items-center">

                        <div className="single_input_7sft">
                          <ClientCaptcha
                            captchaCode={captchaHandler}
                            fontColor="#ffffff"
                            backgroundColor="#005de0"
                          />

                        </div>

                        <div className="single_input_7sft" style={{ marginLeft: '15px' }}>
                          <Form.Control type="text" placeholder="Verify Captcha" value={SubCapt} onKeyPress={(e) => handleKeypress(e)} onChange={(e) => setSubCapt(e.target.value)} />
                        </div>
                      </div>
                      <div style={{ color: 'red' }}>{CaptchaError}</div>
                      <div className="case-study-btn text-center mt-3">
                        <button className="btn_7sft" onClick={submitContact}>
                          Send
                        </button>
                      </div>
                    </Col>

                  </Row>
                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </div>

      <div className="map_wrap_section_7sft section_margin sect_margin">
        <div className="map_wrap_7sft">
          <div className="form_title_7sft">
            <ScrollAnimation animateIn='fadeIn'>
              <h3 className="title">
                Karachi Office
              </h3>
            </ScrollAnimation>


          </div>

          <Container>
            <Row>
              <Col md={4} lg={4} xl={4} sm={12} xs={12}>
                <div className="single_contact_info_7sft">
                  <div className="info_icon_7sft">
                    <FiPhoneCall />
                  </div>
                  <div className="info_contact_7sft">
                    <h5 className="title">Phone</h5>
                    <p><img src={configUrl.ImgBaseUrl + "/images/number-all.png"} alt="phone" /></p>
                  </div>
                </div>
              </Col>

              <Col md={4} lg={4} xl={4} sm={12} xs={12}>
                <div className="single_contact_info_7sft">
                  <div className="info_icon_7sft">
                    <FaHome />
                  </div>
                  <div className="info_contact_7sft">
                    <h5 className="title">Address</h5>
                    {/* <p>Shaheen Towers, P.E.C.H.S. Block 6</p> */}
                    <p className="para_contact_7sft">Office No. 23 &amp; 24, 1st Floor, Shaheen Towers, P.E.C.H.S. Block 6, Shahrah-e-Faisal, Karachi 75400, Pakistan.</p>
                  </div>
                </div>
              </Col>


              <Col md={4} lg={4} xl={4} sm={12} xs={12}>
                <div className="single_contact_info_7sft_location">
                  <div className="info_icon_7sft">
                    <HiOutlineLocationMarker />
                  </div>
                  <div className="info_contact_7sft">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14480.231845880691!2d67.069947!3d24.8618699!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2afec2636f22dc11!2s7nSoft%20Systems%20(Pvt.)%20Ltd.!5e0!3m2!1sen!2s!4v1644907726105!5m2!1sen!2s" style={{ height: '210px', width: '100%' }} allowFullScreen={true} loading="lazy" />
                  </div>

                </div>
              </Col>

            </Row>
          </Container>

        </div>
      </div>



      <div className="map_wrap_section_7sft section_margin sect_margin">
        <div className="map_wrap_7sft">
          <div className="form_title_7sft">

            <ScrollAnimation animateIn='fadeIn'>
              <h3 className="title">
                Lahore Office
              </h3>
            </ScrollAnimation>


          </div>

          <Container>
            <Row>

              <Col md={6} lg={6} xl={6} sm={12} xs={12}>
                <div className="single_contact_info_7sft">
                  <div className="info_icon_7sft">
                    <FaHome />
                  </div>
                  <div className="info_contact_7sft">
                    <h5 className="title">Address</h5>
                    {/* <p>Shaheen Towers, P.E.C.H.S. Block 6</p> */}
                    <p className="para_contact_7sft">Showroom #5, Ground Floor, Gulberg Heights, Plot # 6 & 6-A, Block H, Gulberg II, Lahore Pakistan.</p>
                  </div>
                </div>
              </Col>


              <Col md={6} lg={6} xl={6} sm={12} xs={12}>
                <div className="single_contact_info_7sft_location">
                  <div className="info_icon_7sft">
                    <HiOutlineLocationMarker />
                  </div>
                  <div className="info_contact_7sft">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d437.55483954665067!2d74.3569557981784!3d31.52998090836598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4c49baa2bf27719c!2zMzHCsDMxJzQ3LjgiTiA3NMKwMjEnMjQuNyJF!5e0!3m2!1sen!2s!4v1657175423829!5m2!1sen!2s" style={{ height: '210px', width: '100%' }} allowFullScreen={true} loading="lazy" />
                  </div>

                </div>
              </Col>

            </Row>
          </Container>

        </div>
      </div>


      <Footer />
    </>
  );
}

export default ContactUs;