import React from "react";

import MainNavBar from "../components/main_menu";
import Footer from "../components/Footer";
import { Container, Col, Row, Form } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import web_services from "../web_services/web_services";
import configUrl from "../config";
import ScrollAnimation from "react-animate-on-scroll";
import ImageZoom from "react-image-zooom";
import ReactPlayer from 'react-player'

function SingelEvent() {
  const { id } = useParams();
  const [eventData, setEventData] = useState([]);

  function getExtension(filename) {
    return filename.substring(filename.lastIndexOf(".") + 1);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    web_services.getEventById(id).then((res) => {
      setEventData(res.data.event ? res.data.event[0] : []);
      console.log(res.data.event[0])
    })
  }, [])




  return (
    <>
      <MainNavBar />
      <div className="page-banner-section event_backImage" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + '/images/event.png'})` }}>
        <div className="shape-banner2">

        </div>
        {/* <div className="shape-1"></div> */}
        <Container>
          <Container className="page-banner-wrap">
            <Row>
              <Col>
                <div className="page-banner text-center">
                  <h2 className="title">{eventData ? eventData.Title : null}</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>


      <Container>
        <Row className="mt-5">
          <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
            <div className="about_content_7sft job_con">
              <div className="section_title_7sft">
                <div class="section_title_7sft">
                  <ScrollAnimation animateIn='flipInX'>
                    <h3 class="sub_title_7sft" style={{ marginTop: '0px' }}>Event</h3>
                  </ScrollAnimation>

                  <ScrollAnimation animateIn='fadeIn'>
                    <h2 class="title_7sft" style={{ marginTop: '0px' }}> {eventData ? eventData.Title : null}</h2>
                  </ScrollAnimation>
                </div>

                <ScrollAnimation animateIn='flipInX'>
                  <h2 class="sub_title_7sft ">Description</h2>
                </ScrollAnimation>

                <p className="ul_list">
                  {eventData ? eventData.Description : null}
                </p>

                <Row>
                  {eventData ? eventData.images?.map((res, i) => {
                    if (res === '') {
                      return null; // Skip rendering if img is empty
                    }
                    return (
                      <Col md={3} lg={3} xl={3} xs={12} sm={12} className="event_card_height mb-3" >
                        <div className="event_img" key={i} >
                          {getExtension(res) == "mp4"
                            ? <ReactPlayer controls width="280px" height="300px"
                              url={res ? configUrl.APIImagesdURL + res : null} />
                            : <ImageZoom className="event_inner_img" src={res ? configUrl.APIImagesdURL + res : null} zoom="400" />
                          }
                        </div>

                      </Col>
                    )
                  }) : null}

                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}


export default SingelEvent;
