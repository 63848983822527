import React from 'react';
import { Col, Form, Row } from "react-bootstrap";
import '../../pages_scss/Login.scss';
import { useState } from 'react';
import axiosConfig from "../../axiosConfig"
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useSearchParams } from 'react-router-dom';
import configUrl from '../../config';

const Password = () => {
    const [password, setPassword] = useState();
    const [confPass, setConfPass] = useState();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();


    function ResetPass(e) {
        e.preventDefault();
        if (password === confPass) {
            axiosConfig.post(`/updatePassword?token=${searchParams.get('token')}`, {
                password: password,
            }).then((res) => {
                console.log(res);
                if (res.status == 200) {
                    toast.info("Password reset sucessful!");
                    setPassword('');
                    setConfPass('');
                } else {
                    toast.info("No Email Found");
                }
            })
        } else {
            toast.info("Passwords do not match!");
        }
    }

    function ClickFunc(e) {
        e.preventDefault();
        navigate('/hrways');
    }

    return (
        <>
            <div className="App-header ">
                <Form className="form_class Widget_widget__Card">
                    <Row>
                        <Col md={12} className='d-flex justify-content-center align-items-center mb-3'>
                            <img src={`${configUrl.ImgBaseUrl}/images/logo.png`} className="logo" />
                        </Col>
                        <Col md={12} className='mb-3'>
                            <div className="single_input_7sft">
                                <Form.Control type="password" placeholder="Password" onChange={(e) => { setPassword(e.target.value) }} />
                            </div>
                        </Col>
                        <Col md={12} className='mb-3'>
                            <div className="single_input_7sft">
                                <Form.Control type="password" placeholder="Confirm Password" onChange={(e) => { setConfPass(e.target.value) }} />
                            </div>
                        </Col>

                    </Row>
                    <button className="btn_log_7sft mb-3" onClick={(e) => { ResetPass(e) }}>
                        Reset
                    </button>
                    <h6 className="forget-btn" onClick={(e) => { ClickFunc(e) }}>Back to Login</h6>
                </Form>

                <div className="case-study-btn text-center mt-3">
                </div>
            </div>
        </>
    );
};

export default Password;  