import { Container, Col, Row } from "react-bootstrap";
import './Footer.scss';
import { NavLink } from "react-router-dom";
import { FaFacebookF } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { FaLinkedinIn } from 'react-icons/fa';
import { BiPhoneCall } from 'react-icons/bi';
import { BiLocationPlus } from 'react-icons/bi';
import { FaArrowUp } from "react-icons/fa";
import configUrl from "../config";

function Footer() {

  window.onscroll = function () { scrollFunction() };

  const Scrollbutton = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  function scrollFunction() {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      document.getElementById("topScroll").style.display = "block";
    } else {
      document.getElementById("topScroll").style.display = "none";
    }
  }
  return (
    <>
      <Container fluid className="section footer-section section_margin" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + '/images/footer_bg.jpg'})` }}>
        <Container>
          <Container className="footer-widget-wrap">
            <Row>
              <Col md={6} lg={3} xl={3} sm={12} xs={12}>
                <div>
                  <a className="footer-logo" href="#">
                    <img src={`${configUrl.ImgBaseUrl}/images/7n-logo.svg`} alt="Footer" className="logo" />
                  </a>
                </div>

                <div className="widget-info">
                  <ul>

                    <li className="d-flex align-items-center">
                      <div className="info-icon">
                        <BiPhoneCall />
                      </div>
                      <div className="info-text">
                        <span>
                          <img src={`${configUrl.ImgBaseUrl}/images/number_lue.png`} alt="number_blue" />
                        </span>
                      </div>
                    </li>

                    <li className="d-flex">
                      <div className="info-icon">
                        <BiLocationPlus />
                      </div>
                      <div className="info-text">
                        <span>
                          Office No. 23 &amp; 24, 1st Floor,
                          Shaheen Towers, P.E.C.H.S.
                          Block 6, Shahrah-e-Faisal,
                          Karachi 75400, Pakistan.
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col md={6} lg={3} xl={3} sm={12} xs={12}>
                <div className="footer-widget">
                  <h4 className="footer-widget-title"> Our Services</h4>
                  <div className="widget-link">
                    <ul className="link">
                      <li>
                        <NavLink className="navlnk" to="/services/consulting">
                          Consulting Services
                        </NavLink>
                      </li>

                      <li>
                        <NavLink className="navlnk" to="/services/managed">
                          Managed IT Services
                        </NavLink>
                      </li>

                      <li>
                        <NavLink className="navlnk" to="/services/softwaredev">
                          Software Development
                        </NavLink>
                      </li>

                      <li>
                        <NavLink className="navlnk" to="/services/cloud">
                          Cloud Migration
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>

              <Col md={6} lg={3} xl={3} sm={12} xs={12}>
                <div className="footer-widget">
                  <h4 className="footer-widget-title"> Useful Links</h4>
                  <div className="widget-link">
                    <ul className="link">
                      <li>
                        <NavLink className="navlnk" to="/">
                          Terms &amp; Conditions
                        </NavLink>
                      </li>

                      <li>
                        <NavLink className="navlnk" to="/about-us">
                          About Company
                        </NavLink>
                      </li>

                      <li>
                        <NavLink className="navlnk" to="/">
                          Policy
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>

              <Col md={6} lg={3} xl={3} sm={12} xs={12}>
                <div className="footer-widget">
                  <h4 className="footer-widget-title"> Support</h4>
                  <div className="widget-link">
                    <ul className="link">
                      <li>
                        <NavLink className="navlnk" to="/contact-us">
                          Contact Us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="navlnk" to="/">
                          FAQS
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>  
              </Col>

            </Row>
          </Container>
        </Container>

        <Container fluid className="footer-copyright-area">
          <Container>
            <div className="footer-copyright-wrap">
              <Row>
                <Col md={6} xs={12} sm={12} className=" d-flex align-items-center foot_text">
                  <div className="copyright-text ">
                    <p>Copyright © 7NSoft Systems {new Date().getFullYear()}, All rights reserved. </p>
                  </div>
                </Col>

                <Col md={6} xs={12} sm={12} className="foot_icons">
                  <div className="copyright-social">
                    <ul className="social">

                      <li class="foot_icon">
                        <a className="navlnk" target="_blank" href="https://www.facebook.com/7nsoftsystemsCareers/">
                          <FaFacebookF />
                        </a>

                      </li>

                      <li class="foot_icon">
                        <a className="navlnk" target="_blank" href="https://pk.linkedin.com/company/7nsoft-hr">
                          <FaLinkedinIn />
                        </a>
                      </li>

                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Container>
      </Container>
      <div className="scrollButton" onClick={Scrollbutton} id="topScroll">
        <FaArrowUp />
      </div>
    </>
  );
}

export default Footer;