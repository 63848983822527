import { Container, Col, Row, NavDropdown, Nav, Navbar } from "react-bootstrap";
import './../pages_scss/Home.scss';
import CountUp from 'react-countup';

import configUrl from "../config";
import ScrollAnimation from "react-animate-on-scroll";

function Counter() {
  return (
    <>
      <div className="counter_section_7sft " style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + '/images/Highlight-banner_USYD.jpg'})` }}>

        <Container>
          <div className="counter_wrap_7sft">

            <Row>
              <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                <div className="main_head_vac section_margin">
                  <div className="form_title_7sft">
                    <ScrollAnimation animateIn='flipInX'>
                      <h3 className="title mb-5" style={{ color: '#ffff' }}>
                        Why Should You Hire 7nSoft? The Data Speaks for Itself
                      </h3>
                    </ScrollAnimation>


                  </div>
                </div>
              </Col>
              <Col md={6} lg={3} xl={3} sm={12} xs={12} className="mb-5">
                <div className="counter_item_7sft">
                  <div className="counter_icons_7sft">
                    <img src={configUrl.ImgBaseUrl + "/icons/counter-1.png"} alt="" />
                  </div>
                  <div className="counter_text_7sft">
                    <span>
                      <span><CountUp end={354} duration={4} /> </span>
                      +
                    </span>
                    <p>Completed Projects</p>
                  </div>
                </div>
              </Col>

              <Col md={6} lg={3} xl={3} sm={12} xs={12} className="mb-5">
                <div className="counter_item_7sft">
                  <div className="counter_icons_7sft">
                    <img src={configUrl.ImgBaseUrl + "/icons/counter-2.png"} alt="" />
                  </div>
                  <div className="counter_text_7sft">
                    <span>
                      <span><CountUp end={199} duration={4} /> </span>
                      +
                    </span>
                    <p>Satisfied Clients</p>
                  </div>
                </div>
              </Col>

              <Col md={6} lg={3} xl={3} sm={12} xs={12} className="mb-5">
                <div className="counter_item_7sft">
                  <div className="counter_icons_7sft">
                    <img src={configUrl.ImgBaseUrl + "/icons/counter-3.png"} alt="" />
                  </div>
                  <div className="counter_text_7sft">
                    <span>
                      <span><CountUp end={99} duration={4} /></span>
                      %
                    </span>
                    <p>Web Site Analyse</p>
                  </div>
                </div>
              </Col>

              <Col md={6} lg={3} xl={3} sm={12} xs={12} className="mb-5">
                <div className="counter_item_7sft">
                  <div className="counter_icons_7sft">
                    <img src={configUrl.ImgBaseUrl + "/icons/counter-4.png"} alt="" />
                  </div>
                  <div className="counter_text_7sft">
                    <span>
                      <span><CountUp end={321} duration={4} /></span>
                      +
                    </span>
                    <p>Clients Supoort Done</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

        </Container>
      </div>
    </>
  );
}

export default Counter;