import React from "react";
import Footer from "../components/Footer";
import { Container, Col, Row } from "react-bootstrap";
import '../pages_scss/AboutUs.scss';
import MainNavBar from "../components/main_menu";
import Counter from "../components/Counter";
import { useEffect } from 'react';

import configUrl from "../config";
import ScrollAnimation from "react-animate-on-scroll";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <MainNavBar />
      <div className="page-banner-section about_backImage" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + '/images/about.jpg'})` }}>
        <div className="shape-banner2">

        </div>
        {/* <div className="shape-1"></div> */}
        <Container>
          <Container className="page-banner-wrap">
            <Row>
              <Col>
                <div className="page-banner text-center">
                  <h2 className="title">About Us</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <Container>
        <Row className="mt-5">
          <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
            <div className="about_content_7sft">
              <div className="section_title_7sft">
                <div class="section_title_7sft">

                  <ScrollAnimation animateIn='flipInX'>
                    <h3 class="sub_title_7sft">Who We Are</h3>
                    <h2 class="title_7sft">IT Modernization is What We Do, Innovation is What We Love</h2>
                  </ScrollAnimation>


                </div>
                <p>
                  7nSoft is a bunch of technology-driven people with an aim to transform the business world using technology as the lever. We have been operating since 2000 and have collaborated with some of the biggest players from different industries to turn around their businesses using modern IT solutions.<br /><br />
                  We have a team of passionate IT experts across diversified industries with an illustrious career portfolio. Our team has pulled off some of the major projects which turned out to be a tremendous success for our valued clients.<br /><br />
                  We provide comprehensive IT solutions ranging from consulting services and software development to managed IT services and cloud migration. We deploy innovative technology solutions taking into consideration the risks and criticalities of an organization’s current IT portfolio. Our best-in-class IT solutions aim to mitigate existing risks associated with legacy systems and accelerate business processes through agile, secure, and cost-friendly IT functions that positively impact an organization’s bottom line.
                </p>
              </div>
            </div>
          </Col>
        </Row>

      </Container>
      <Counter />
      <Footer />

    </>
  );
}

export default AboutUs;