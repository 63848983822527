import React from "react";
import Footer from "../components/Footer";
import MainNavBar from "../components/main_menu";
import { Container, Col, Row, Form } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../pages_scss/Career.scss'
import web_services from "../web_services/web_services";

import { Pagination } from "react-pagination-bar";
import Slider from "react-slick";
import configUrl from "../config";
import axiosConfig from "../axiosConfig";
import { toast } from "react-toastify";
import TypeWriterEffect from 'react-typewriter-effect';
import ClientCaptcha from "react-client-captcha";
import { FaRegMoneyBillAlt, FaHeartbeat, FaCalendarAlt, FaBalanceScaleRight } from "react-icons/fa"
import { MdHolidayVillage } from "react-icons/md";
import { BsGraphUp } from "react-icons/bs";
import { BsSunFill } from "react-icons/bs"
import ScrollAnimation from "react-animate-on-scroll";
import { ThreeCircles } from 'react-loader-spinner';


function Career() {
  const [careerState, setCareerState] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const pagePostsLimit = 5;

  const [jobs, setJob] = useState([]);
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [cv, setCV] = useState("");
  const [Capt, setCapt] = useState("");
  const [SubCapt, setSubCapt] = useState("");

  const [nameError, setNameErr] = useState("");
  const [emailError, setEmailErr] = useState("");
  const [phoneError, setPhoneErr] = useState("");
  const [cvError, setCVErr] = useState("");
  const [CaptchaError, setCaptchaErr] = useState("");
  const [jobTitle, setjobTitle] = useState("");
  const [imagesUrl, setimagesUrl] = useState([]);



  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
    setTimeout(() => {
      web_services.getJobs().then((res) => {
        setJob(res.data.jobs ? res.data.jobs : []);
      })
      setCareerState(true)
    }, 5000)



    web_services.getPic().then((resUrl) => {
      setimagesUrl(resUrl.data.images);
    })

  }, [])

  const captchaHandler = (cap) => {
    setCapt(cap);
  }

  const validate = () => {

    let NameValid = false;
    let EmailValid = false;
    let PhoneValid = false;
    let CvValid = false;
    let CaptchaValid = false;

    if (Name == "" || Name.trim() == "") {
      setNameErr("Please Enter a Name");
      NameValid = false;
    }
    else {
      setNameErr("");
      NameValid = true;
    }

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (Email == "" || Email.trim() == "") {
      setEmailErr("Please Enter an Email");
      EmailValid = false;
    }
    else if (!pattern.test(Email)) {
      setEmailErr("Invalid Email");
      EmailValid = false;
    }
    else {
      setEmailErr("");
      EmailValid = true;
    }

    if (Phone == "" || Phone.trim() == "" || Phone.length != "11") {
      setPhoneErr("Please Enter a Valid Number");
      PhoneValid = false;
    }
    else {
      setPhoneErr("");
      PhoneValid = true;
    }

    if (cv == "" || cv == null || cv == undefined) {
      setCVErr("Attach your Resume");
      CvValid = false;
    }
    else {
      setCVErr("");
      CvValid = true;
    }

    if (Capt === SubCapt) {
      setCaptchaErr("");
      CaptchaValid = true;
    }
    else {
      setCaptchaErr("Invalid Captcha");
      CaptchaValid = false;
    }


    if (NameValid && EmailValid && PhoneValid && CvValid && CaptchaValid) {
      return true;
    }
    else {
      return false;
    }
  }

  const submitApp = (e) => {
    // document.querySelector('#retryButton').click()
    let isvalidate = validate();
    if (isvalidate) {
      let formData = new FormData();
      // formData.append('Job_Id', id);
      formData.append('Job_title', jobTitle);
      formData.append('Name', Name);
      formData.append('Email', Email);
      formData.append('Phone', Phone);
      formData.append('cv', cv);


      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
      }

      axiosConfig.post("/simpleApplyJob", formData, config)
        .then(response => {
          console.log(response);
          setName("");
          setEmail("");
          setPhone("");
          setCV("");
          setjobTitle("")
          document.getElementById("exampleFormControlFile1").value = "";
          setSubCapt("");
          toast.info(response.data.message);
          document.querySelector('#retryButton').click()
          // setjobsTF(true);
        })
    }
  }


  const properties = {
    duration: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          dots: true,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          dots: true,
        }
      },

      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          dots: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          dots: true,
        }
      },

      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          dots: true,
        }
      }
    ]
  };

  const goSingleJob = (id) => {
    navigate({ pathname: `/careers/detail/${id}` })
  }


  const fileUploader = (e) => {
    console.log(e.target.files[0].type)
    if (e.target.files[0].type == "application/pdf" || e.target.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      setCV(e.target.files[0]);
      setCVErr("")
    } else {
      setCVErr("File Type Not Supported")
      document.getElementById("exampleFormControlFile1").value = "";
    }

  }

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      submitApp();
    }
  };

  return (
    <>
      <MainNavBar />

      <div className="page-banner-section career_backImage" style={{ backgroundImage: `url(${configUrl.ImgBaseUrl + 'images/career.jpg'})` }}>
        <div className="shape-banner2">
        </div>
        {/* <div className="shape-1"></div> */}
        <Container>
          <Container className="page-banner-wrap">
            <Row>
              <Col>
                <div className="page-banner text-center">
                  <h2 className="title">Careers</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      {/* CONTENT */}

      <Container>
        <Row className="mt-5">
          <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
            <div className="about_content_7sft">
              <div className="section_title_7sft">
                <div class="section_title_7sft mb-3">

                  <ScrollAnimation animateIn='fadeIn'>
                    <h2 class="title_7sft">Career</h2>
                  </ScrollAnimation>


                </div>

                <ScrollAnimation animateIn='flipInX'>
                  <h3 class="sub_title_7sft mb-1">A Lucrative Career is Awaiting You At 7nSoft:</h3>
                </ScrollAnimation>


                <p>
                  7nSoft values individuals who are pushed by passion and driven by innovation. Our work culture is deeply influenced by technological disruption and our team leaders are at the helm of this force. We are a team of ambitious individuals with a problem-solving attitude towards work. We provide a conducive environment for promising individuals to foster innovative ideas and bring the best out of their abilities. Our work environment gives everyone an equal opportunity to grow and level up their skills to compete with those at the top of the technology league.
                  We have built a fair and transparent work culture where excellence is rewarded and equality is measured at every step of our employees’ journey. Everyone working at 7nSoft is committed to one promise: making businesses better with innovative solutions. And we need individuals who can help us continue to deliver this promise.
                  We need you if you are:
                </p>

                <ul className="list_content_sub">
                  <li><h6 class="sub_title_sub mb-1">Knowledgeable and Skilled in IT functions</h6></li>
                  <li><h6 class="sub_title_sub mb-1">Problem-solver</h6></li>
                  <li><h6 class="sub_title_sub mb-1">Result-Oriented</h6></li>
                  <li><h6 class="sub_title_sub mb-1">Passionate</h6></li>
                  <li><h6 class="sub_title_sub mb-3">And INNOVATIVE</h6></li>
                </ul>

                <ScrollAnimation animateIn='flipInX'>
                  <h2 class="sub_title_7sft ">Benefits:</h2>
                </ScrollAnimation>

                <ul className="ul_list benif">
                  <li><i><FaRegMoneyBillAlt /></i>  Market competitive salary</li>
                  <li><i><FaHeartbeat /></i> Medical Insurance (Self, Spouse & Children), Life Insurance, OPD allowance</li>
                  <li><i><MdHolidayVillage /></i> Annual Leaves</li>
                  <li><i><BsGraphUp /></i> Handsome annual increments</li>
                  <li><i><FaCalendarAlt /></i> 2 Weekly off</li>
                  <li><i><FaBalanceScaleRight /></i>Work life balance</li>
                  <li><i><BsSunFill /></i> Employee engagement activities</li>

                </ul>

                <ScrollAnimation animateIn='flipInX'>
                  <h3 class="sub_title_7sft mt-5">A Sneak Peek Into Life At 7nsoft:</h3>
                </ScrollAnimation>




                <Slider {...properties}>

                  {imagesUrl.map((res) => {
                    return (

                      <div className="image_wraper" style={{ padding: '0px 10px' }}>
                        <img src={`${configUrl.APIImagesdURL}images/sneakpeak/` + res} />
                      </div>

                    )
                  })}

                </Slider>


              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* CONTENT */}

      <Container>

        <div className="main_head_vac section_margin">

          <ScrollAnimation animateIn='flipInX'>
            <h3 class="sub_title_career mb-1">Want to be a part of 7nSoft? Check out our latest openings below.</h3>
          </ScrollAnimation>



          <div className="form_title_7sft mt-4">
            < >

              <ScrollAnimation animateIn='fadeIn'>
                <h3 className="title">
                  Open Vacancies
                </h3>
              </ScrollAnimation>

            </>
          </div>
        </div>
          <>
            {jobs.length >= 1 ? <div>
              {jobs.slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit).map((resJob, i) => {
                return (
                  <div className="job_section_7sft" key={i}>
                    <div className="single_job_wrapper">
                      <Row>
                        <Col md={3} lg={3} xl={3} sm={12} xs={12}>
                          <div className="job_name_wrapper">
                          {resJob.Active == 0 ?
                                 <h5>{resJob.job_title}</h5>
                                :
                                <h5 onClick={() => { goSingleJob(resJob.SharedLink) }}>{resJob.job_title}</h5>
                              }
                           
                            <p>{resJob.Job_Location}</p>
                            <p>{new Date(resJob.Posted_Date).toDateString()}</p>
                            <span className="status">Status : </span>
                            {resJob.Active == 0 ? <span className="status_inactive">Closed</span> : <span className="status_ative"> Active</span>}



                          </div>
                        </Col>
                        <Col md={6} lg={6} xl={6} sm={12} xs={12}>
                          <div className="job_description_wrapper">
                            <h6>Description</h6>
                            <p>
                              {resJob.job_description}<br />
                              Requirements:<br />
                              {resJob.job_requirements}
                            </p>

                          </div>
                        </Col>
                        <Col md={3} lg={3} xl={3} sm={12} xs={12} className="d-flex justify-content-end alig-items-center">
                          <div className="job_description_wrapper_7sft d-flex align-items-center justify-content-end">
                            <div className="case-study-btn text-center">

                              {resJob.Active == 0 ?
                                <button className="btn_7sft_disabled" disabled>
                                  Apply Now
                                </button>
                                :
                                <button className="btn_7sft" onClick={() => { goSingleJob(resJob.SharedLink) }}>
                                  Apply Now
                                </button>
                              }
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )
              })}
              <div className="d-flex justify-content-center align-items-center mt-5">
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={pagePostsLimit}
                  onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                  totalItems={jobs.length}
                  pageNeighbours={2}
                />
              </div>
            </div> : <div className="data_not_found">
              <ThreeCircles
                height="80"
                width="100%"
                color='rgb(0, 93, 224)'
              />
            </div>}
          </>
      





      </Container>



      <div className="contact_form_section section_margin">
        <Container>
          <div className="contact_wrap_7sft">
            < >
              <ScrollAnimation animateIn='flipInX'>
                <h3 class="sub_title_career mb-1"> Looking for other title? drop your CV here</h3>
              </ScrollAnimation>
            </>
            <Row className="justify-content-center">
              <Col md={8} lg={8} sm={12} xs={12} xl={8}>
                <div className="form_title_7sft">

                  <ScrollAnimation animateIn='fadeIn'>
                    <h3 className="title">
                      Apply Now
                    </h3>
                  </ScrollAnimation>

                </div>

                <div className="contact_form_wrap_7sft">
                  <Row>
                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Label>Your Name <i style={{ color: 'red' }}>*</i></Form.Label>

                        <Form.Control type="text" value={Name} onChange={(e) => { setName(e.target.value) }} />
                        <div style={{ color: 'red' }}>{nameError}</div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Label>E-mail <i style={{ color: 'red' }}>*</i></Form.Label>
                        <Form.Control type="text" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
                        <div style={{ color: 'red' }}>{emailError}</div>

                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Label>Phone number<i style={{ color: 'red' }}>*</i></Form.Label>
                        <Form.Control type="number" value={Phone} onChange={(e) => { setPhone(e.target.value.slice(0, 11)) }} />
                        <div style={{ color: 'red' }}>{phoneError}</div>

                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Label>Title/Position applied for<i style={{ color: 'red' }}>*</i></Form.Label>
                        <Form.Control type="text" value={jobTitle} onChange={(e) => { setjobTitle(e.target.value) }} />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Label>Upload CV<i style={{ color: 'red' }}>*</i></Form.Label>
                        <input type="file" title="" class="form-control" accept=".pdf,.doc,.docs,.docx" id="exampleFormControlFile1" onChange={(e) => { fileUploader(e) }} />
                        <div style={{ color: 'red' }}>{cvError}</div>
                        <div style={{ color: 'gray', fontSize: '13px' }} className="mt-2">Supported Formats: pdf/docx </div>
                      </div>
                    </Col>



                    <Col md={12}>
                      <div className="d-flex align-items-center">
                        <div className="single_input_7sft">
                          <ClientCaptcha
                            captchaCode={captchaHandler}
                            fontColor="#ffffff"
                            backgroundColor="#005de0"
                          />

                        </div>

                        <div className="single_input_7sft" style={{ marginLeft: '15px' }}>
                          <Form.Control type="text" placeholder="Verify Captcha" value={SubCapt} onKeyPress={(e) => handleKeypress(e)} onChange={(e) => setSubCapt(e.target.value)} />
                        </div>
                      </div>
                      <div style={{ color: 'red' }}>{CaptchaError}</div>

                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <div className="case-study-btn text-center mt-3">
              <button className="btn_7sft" to="/ourteam" onClick={submitApp}>
                Submit
              </button>
            </div>
          </div>
        </Container>
      </div>

      <Footer />
    </>
  );
}

export default Career;